import {
    UPDATE_PINCH_IN_IMAGE_ZOOMED,
    UPDATE_DOUBLE_CLICK_IMAGE_ZOOMED,
    RESET_IMAGE_ZOOM_STATUS,
} from '../actionTypes/PdpImageZoomActionType';

export const getPinchInZoomed = (newPinchinZoomState) => ({
    type: UPDATE_PINCH_IN_IMAGE_ZOOMED,
    payload: newPinchinZoomState,
});
export const getDoubleClickZoomed = (newDoubleclickZoomState) => ({
    type: UPDATE_DOUBLE_CLICK_IMAGE_ZOOMED,
    payload: newDoubleclickZoomState,
});
export const resetImageZoomStatus = (newZoomState) => ({
    type: RESET_IMAGE_ZOOM_STATUS,
    payload: newZoomState,
});

export default getDoubleClickZoomed;

const LARGEAPPLIANCE = 'largeAppliance';
const ENSEMBLE = 'ENSEMBLE';
const REGULAR = 'REGULAR';
const OUT_OF_STOCK_MESSAGE = 'Out of Stock Online';
const SHIPTOHOME = 'Ship to Home';
const DELIVERY_HOME = 'HOME';
const SHIPTOHOMEORSTORE = 'Ship to Home or Store';
const FREESAMEDAYPICKUP = 'Store Pickup';
const SAMEDAYPICKUP = 'Store Pickup';
const NOTAVAILABLE = 'Unavailable';
const AVAILABLE = 'Available';
const GETITTODAY = 'Get it Today';
const AVAILABLELOW = 'Available';
const OUTOFSTOCK = 'Out of Stock';
const LIMITEDSTOCK = 'Limited Stock';
const CLEARANCE = 'clearance';
const CLOSEOUT = 'closeout';
const ens = 'ens';
const pTmplType = 'pTmplType';
const carePlan = 'care plan';
const CHECKOUT_URL = '/c/index.html';
const CHECKOUT_DESKTOP_URL = '/jsp/cart/viewShoppingBag.jsp';
const FURNITURE = 'FURNITURE';
const MADE_TO_MEASURE = 'MadeToMeasure';
const HEMMING = 'HEMMING';
const GIFTCARD = 'GIFTCARD';
const BOPIS_SELECTED_OPTION = 'samedaypickup';
const ATC_BOPIS_SELECTED_MESSAGE = 'same day pickup';
const MTM = 'mtm';
const ISIRISFALLBACK = 'isIRISFallBack';
const SIZE_DIMENSIONS = ['WIDTH', 'SIZE', 'CHEST', 'WAIST', 'LENGTH'];
const SELECT_A_COLOR = 'Select a Color';
const MINI_PDP_SELECT_A_COLOR = 'Please Select a Color';
const BEAUTY = 'beauty';
const C23 = 'c23';
const DEFAULT = 'DEFAULT';
const CODE = 'with code';
const FEATURE_SHOP = '/m/feature-shop/';
const EXTENDEDSIZERANGE = 'ExtendedSizeRange';

export default {
    LARGEAPPLIANCE,
    ENSEMBLE,
    ens,
    REGULAR,
    OUT_OF_STOCK_MESSAGE,
    SHIPTOHOME,
    SHIPTOHOMEORSTORE,
    FREESAMEDAYPICKUP,
    SAMEDAYPICKUP,
    NOTAVAILABLE,
    AVAILABLE,
    GETITTODAY,
    AVAILABLELOW,
    OUTOFSTOCK,
    LIMITEDSTOCK,
    CLEARANCE,
    CLOSEOUT,
    pTmplType,
    carePlan,
    CHECKOUT_URL,
    CHECKOUT_DESKTOP_URL,
    FURNITURE,
    MADE_TO_MEASURE,
    BOPIS_SELECTED_OPTION,
    ATC_BOPIS_SELECTED_MESSAGE,
    HEMMING,
    GIFTCARD,
    DELIVERY_HOME,
    MTM,
    ISIRISFALLBACK,
    SIZE_DIMENSIONS,
    SELECT_A_COLOR,
    MINI_PDP_SELECT_A_COLOR,
    BEAUTY,
    C23,
    DEFAULT,
    CODE,
    FEATURE_SHOP,
    EXTENDEDSIZERANGE,
};

export const productDimensions = { // eslint-disable-line
    SIZE_RANGE: 'SIZE_RANGE',
    SIZE: 'SIZE',
    COLOR: 'COLOR',
    NECK_SIZE: 'NECK_SIZE',
    SLEEVE: 'SLEEVE',
    PETITE: 'petite',
    NOTAVAILABLE: 'Not Available',
    PRODUCT: 'PRODUCT',
    LENGTH: 'LENGTH',
    WIDTH: 'WIDTH',
};

export const CUSTOM_OPTIONS_TYPE_TOKEN = {
    WDF: 'WDF',
    WD: 'WD',
    LN: 'LN',
    LNF: 'LNF',
};

export const giftRegistry = { // eslint-disable-line
    registryLinks: {
        prod: {
            find: 'https://registry.jcpenney.com/findRegistry',
            manage: 'https://registry.jcpenney.com/viewRegistry',
        },
        test: {
            find:
                'https://test-registry.jcpenney.com/skavastream/studio/reader/stg/JCP_Registry/findRegistry',
            manage:
                'https://test-registry.jcpenney.com/skavastream/studio/reader/stg/JCP_Registry/viewRegistry',
        },
    },
    addToRegistryErrCode: 'SVC_GR_ERR_REGISTRY_NF',
    addToRegistryInvalidSession: 'SRV_SESSION_INVALID',
    skavaCampaignId: 732,
};

export const bopisIncentive = {
    eligibilityMessage: 'Pickup Discount in Select Stores!',
};

export const promiseBopisNodes = {
    aggregatedBopusNode: 'aggregatedBopus',
    bopusNode: 'bopus',
};

export const TrueToSizeResponse = {
    RunsOneSizeStepLarge: 'RunsOneSizeStepLarge',
    MayRunOneSizeStepLarge: 'MayRunOneSizeStepLarge',
    RunsTrueToSizeLarge: 'RunsTrueToSizeLarge',
    RunsTrueToSizeSmall: 'RunsTrueToSizeSmall',
    MayRunOneSizeStepSmall: 'MayRunOneSizeStepSmall',
    RunsOneSizeStepSmall: 'RunsOneSizeStepSmall',
    RunsTrueToSize: 'RunsTrueToSize',
};

export const TrueToSizeSmallMsg = {
    label: 'Runs small',
    message: 'You may want to select a larger size.',
};

export const TrueToSizeLargeMsg = {
    label: 'Runs large',
    message: 'You may want to select a smaller size.',
};

export const TrueToSizeMsg = {
    label: 'True to Size',
    message: 'This item runs true to size.',
};

export const TrueToSizeParam = {
    clientId: 'jcp',
    staging: 'prod',
};

import React from 'react';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import PropTypes from 'prop-types';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import * as styles from './RewardsInfoOnHover.css';

const cx = classNames.bind(styles);

export const RewardsInfoOnHover = (props) => {
    const {
        rewardsDetails,
        rewardsHoverInfoMessagesTexts,
        userName,
        enableRewardsPilotProgram,
    } = props;
    const isEligibleForRewardsPilot =
        (Cookies.load('eligibleForRewardsPilot') && enableRewardsPilotProgram) || false;
    const {
        points: {
            dollarThreshold = 10,
            earnedPoints = 0,
            pointsAway = 200,
            pointsThreshold = 200,
        } = {},
        certificates: { totalCertsValue: currentRewards = 0, data = [] } = {},
    } = rewardsDetails;
    const expiryDates = data.map((certificate) => certificate.expiryDate);
    let expiryDate = (
        expiryDates.sort((value1, value2) => value1.localeCompare(value2))[0] || ''
    ).slice(0, -5);
    if (expiryDate.charAt(0) === '0') {
        expiryDate = expiryDate.slice(1);
    }
    const hideRewardsSection = isEmpty(rewardsDetails);
    const {
        startEarningText = [],
        learnMoreText,
        learnMoreLink,
        currentRewardsText = [],
        expiresText,
        rewardsPointsText,
        progressCircleStyles,
        pointsAwayText = [],
        newEarningsText = [],
        earnedPointThreshold = 50,
        earnedPointThresholdPercentage = 0.25,
    } = rewardsHoverInfoMessagesTexts;

    const calculatedEarnedThreshold = isEligibleForRewardsPilot
        ? pointsThreshold * earnedPointThresholdPercentage
        : earnedPointThreshold;

    const noCurrentRewardsAndPoints = currentRewards === 0 && earnedPoints === 0;
    const isCurrentRewardsPresent = currentRewards !== 0;
    const isPointsCircleRequired = earnedPoints !== 0 && earnedPoints >= calculatedEarnedThreshold;

    const renderLearnMoreLink = () => {
        return (
            <div data-automation-id="rewards-learn-more-link">
                <a
                    className={`${dt([
                        'text-xsmall',
                        'font-open-sans-regular',
                        'text-gray-60',
                        'underline',
                    ])} ${cx('learnMoreLink')}`}
                    href={learnMoreLink}
                >
                    {learnMoreText}
                </a>
            </div>
        );
    };

    const renderStartEarningText = () => {
        return (
            <div
                data-automation-id="rewards-start-earning-text"
                className={dt([
                    'text-black',
                    'text-center',
                    'font-open-sans-regular',
                    'text-small',
                    'mt-2',
                ])}
            >
                {startEarningText[0]}
                <span className="font-bold">{` $${dollarThreshold} ${startEarningText[1]} `}</span>
                {`${startEarningText[2]}${pointsThreshold}${startEarningText[3]}`}
                {renderLearnMoreLink()}
            </div>
        );
    };

    const renderCurrentRewardsText = () => {
        return (
            <div
                data-automation-id="rewards-current-certs-text"
                className={dt([
                    'text-black',
                    'text-medium',
                    'font-open-sans-regular',
                    'text-center',
                    'mt-1',
                ])}
            >
                <div>
                    {currentRewardsText[0]}{' '}
                    <span
                        className={dt(['font-bold'])}
                    >{`$${currentRewards} ${currentRewardsText[1]}`}</span>
                </div>
                <div
                    data-automation-id="rewards-certs-expiry-text"
                    className={dt(['text-gray-60', 'font-open-sans-regular', 'text-xsmall'])}
                >{`${expiresText} ${expiryDate}`}</div>
            </div>
        );
    };

    const renderPointsCircle = () => {
        const percentageOfRewards = Math.round((earnedPoints / pointsThreshold) * 100);
        return (
            <div className={cx('progressBar')}>
                <CircularProgressbarWithChildren
                    className={`${dt(['font-open-sans-regular', 'text-gray-60'])} ${cx(
                        'progressCircle'
                    )}`}
                    value={percentageOfRewards}
                    styles={progressCircleStyles}
                >
                    <span className={dt(['text-large'])}>
                        <span className={dt(['text-black', 'font-bold'])}>{earnedPoints}</span>/
                        {pointsThreshold}
                    </span>
                    <div className={dt(['text-xsmall'])}>{rewardsPointsText}</div>
                </CircularProgressbarWithChildren>
            </div>
        );
    };

    const renderPointsAwayText = () => {
        const pointsAwayMessagesTexts = isCurrentRewardsPresent ? pointsAwayText : newEarningsText;
        let pointsAwayRenderText = `${pointsAwayMessagesTexts[0]} ${pointsAway} ${pointsAwayMessagesTexts[1]} $${dollarThreshold} ${pointsAwayMessagesTexts[2]}`;
        if (isCurrentRewardsPresent && !isPointsCircleRequired) {
            pointsAwayRenderText = `${pointsAway} ${pointsAwayText[1]} ${pointsAwayText[2]}`;
        }
        return (
            <div
                data-automation-id="rewards-points-away-text"
                className={dt([
                    'text-small',
                    'font-open-sans-regular',
                    'text-black',
                    'text-center',
                    'm-auto',
                ])}
            >
                {pointsAwayRenderText}
                {renderLearnMoreLink()}
            </div>
        );
    };
    return (
        <div
            data-automation-id="rewards-info-hover-container"
            className={dt([
                'px-6',
                'py-4',
                'bg-white',
                'border-b',
                'border-solid',
                'border-gray-15',
            ])}
        >
            <div
                data-automation-id="acc-hover-username"
                className={dt([
                    'font-open-sans-regular',
                    'text-black',
                    'text-large',
                    'text-center',
                ])}
                data-qm-encrypt="true"
            >{`Hi, ${userName}`}</div>
            {!hideRewardsSection && noCurrentRewardsAndPoints && renderStartEarningText()}
            {!hideRewardsSection && isCurrentRewardsPresent && renderCurrentRewardsText()}
            {!hideRewardsSection && (
                <div
                    data-automation-id="rewards-progress-hover-container"
                    className={dt(['flex', 'flex-row', 'justify-between', 'mt-2'])}
                >
                    {isPointsCircleRequired && renderPointsCircle()}
                    {!noCurrentRewardsAndPoints && renderPointsAwayText()}
                </div>
            )}
        </div>
    );
};

RewardsInfoOnHover.defaultProps = {
    rewardsHoverInfoMessagesTexts: {},
    userName: '',
    rewardsDetails: {},
    enableRewardsPilotProgram: false,
};

RewardsInfoOnHover.propTypes = {
    rewardsHoverInfoMessagesTexts: PropTypes.objectOf(PropTypes.any),
    userName: PropTypes.string,
    rewardsDetails: PropTypes.objectOf(PropTypes.any),
    enableRewardsPilotProgram: PropTypes.bool,
};

const mapStateToProps = (state) => {
    const rewardsHoverInfoMessagesTexts = _get(
        state,
        'context.messagesTexts.rewardsInfoOnHover',
        {}
    );
    const enableRewardsPilotProgram = _get(
        state,
        'context.featureFlags.enableRewardsPilotProgram',
        false
    );
    return {
        rewardsHoverInfoMessagesTexts,
        enableRewardsPilotProgram,
    };
};

export default connect(mapStateToProps, null)(RewardsInfoOnHover);

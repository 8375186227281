import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import filter from 'lodash/filter';
import _keyBy from 'lodash/keyBy';
import _merge from 'lodash/merge';
import _values from 'lodash/values';
import _get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames/bind';
import { sha256 } from 'js-sha256';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import Warning from 'yoda-core-components/lib/assets/svg/Warning.svg';
import YDTIcon from 'yoda-core-components/lib/components/Icon';
import CartLarge from 'yoda-core-components/lib/assets/svg/CartLarge.svg';
import CartLine from 'yoda-core-components/lib/assets/svg/CartLine.svg';
import Icon2023JcpenneyLogo from 'yoda-core-components/lib/assets/svg/2023JcpenneyLogo.svg';
import Rewards from 'yoda-core-components/lib/assets/svg/Rewards.svg';
import Button from 'yoda-core-components/lib/components/Button/Button';
import Loader from 'yoda-core-components/lib/components/Loader/Loader';
import List from 'yoda-core-components/lib/components/List/List';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import LocalStorage, {
    SessionStorage,
} from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import {
    parseJson,
    getURLParameterBykey,
    detectisIE,
    updateQueryStringParameter,
    isGuestUser,
} from 'yoda-core-components/lib/helpers/Utils/Utils';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import StoreLine from '../../assets/svg/store-icon-filled.svg';
import Account from '../../assets/svg/account-icon-filled.svg';
import AccountWithDot from '../../assets/svg/account-with-dot.svg';
import StoreInfo from '../StoreInfo/StoreInfo';
import { openAccountMenuPage } from '../../actions/SignInSliderActions';
import config from './Header.config';
import * as styles from './Header.css';
import User from '../../helpers/User/User';
import SiteComponent from '../SiteComponent/SiteComponent';
import FetchRegion from '../FetchRegion/FetchRegion';
import FindStoresAction from '../../actions/FindStoresAction';
import analyticsActions from '../../actions/AnalyticsAction';
import orderAction from '../../actions/OrderAction';
import LocationAction from '../../actions/LocationAction';
import vendorAction from '../../actions/OtherVendorAction';
import {
    loadUserStoreLocationInfo,
    updateStoreOnCartAction,
    updateUserStoreLocationInfo,
} from '../../actions/LocationServiceAction';
import itemCountAction from '../../actions/ItemCountAction';
import {
    openSignInSlider,
    getUserDetails,
    accessTimerStart,
    accessTimerAuthCheck,
    showLogoutTooltip,
    customStepUp,
    openRewardsPromotionLogin,
} from '../../actions/AccountAction';
import GalleryRerenderAction from '../../actions/GalleryRerenderAction';
import TopMiniNav from '../TopMiniNav/TopMiniNav';
import ExitPosLazy from '../ExitPos/ExitPosChunksLazy';
import HamburgerPlaceHolder from './HamburgerPlaceHolder';
import storeActions from '../../actions/SetMyDefaultStoreAction';
import KeepMeLoggedInAction from '../../actions/KeepMeLoggedInAction';
import storeNavigationActions from '../../actions/DepartmentVisualNavigationAction';
import SecondaryNavigationPanel from './SecondaryNavigationPanel';
import StickyTimer from '../StickyTimer/StickyTimer';
import ProductSearch from '../ProductSearch/ProductSearch';
import YodaTooltipLazy from '../YodaTooltip/HeaderChunksAndFooterLazy';
import ThirdPartyServiceButton from './ThirdPartyServiceButton';
import { showLazy } from '../../lazy/actions/LazyAction';
import selectLazy from '../../lazy/selectors/selectLazy';
import { selectShouldRender } from '../../selectors/ContextSelector';
import { selectKeepMeLoggedInThrottleFF } from '../../selectors/KeepMeLoggedInSelector';
import HamburgerAction from '../../actions/HamburgerAction';
import Link from '../Link/Link';
import ThrottleFeatureRender from '../ThrottleFeatureRender/ThrottleFeatureRender';
import MobileBotHamburger from '../MobileBotHamburger/MobileBotHamburger';
import productSearchFocusAction from '../../actions/ProductSearchFocusAction';
import YodaTooltip from '../YodaTooltip/YodaTooltip';
import CartWrapper from './CartWrapper';
import CAMNavWrapper from '../CAMNav/CAMNavLazy';
import { fetchVizNavIrisDepartmentData } from './GetNavigationData';
import iovationLoader from '../../helpers/IovationLoader/IovationLoader';
import {
    headerStore,
    FIND_STORES_SELECT_A_STORE_HEADER_TEXT,
    FIND_STORES_PAGE_SIZE,
    LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
    REWARD_PERSONALIZATION_REWARD_AVAILABLE,
    REWARD_PERSONALIZATION_REWARDS_MEMBER,
    REWARD_PERSONALIZATION_DP_REWARDS_DETAILS,
    REWARD_PERSONALIZATION_POINTS,
    REWARD_PERSONALIZATION_MY_ACCOUNT,
} from '../../common/Constants';
import isCSSFeatureSupported from '../../helpers/Utils/features';
import RewardsInfoOnHover from '../RewardsInfoOnHover/RewardsInfoOnHover';
import { isGalleryPage, isCartPage } from '../../helpers/Utils/pageType';

// Have to remove once the visNav Integration Done
// import mockData from './mockData';
// Have to remove once the visNav Integration Done Ends

const SCROLL_THRESHOLD = 25;
const TIME_THRESHOLD = 300;
const PRIMARY_HEADER_HEIGHT = 56;

const headerLoad = 'headerLoad';
const cx = classNames.bind(styles);

/* istanbul-ignore-next */
export class YodaHeader extends SiteComponent {
    static defaultProps = {
        signedOut: false,
        cartCount: 0,
        headerType: '',
        headerId: 'header',
        isPreview: false,
        deviceType: {},
        accounts: {
            userProfile: {
                firstName: null,
                accountId: null,
                rewardsStatus: null,
                userState: null,
                totalCerts: null,
            },
        },
        bagItemCount: 0,
        actions: {},
        logoLink: 'on',
        shopDepartmentMenuInfo: [],
        primaryLinks: {
            cart: '',
        },
        accountMenuList: {},
        headerLoaded: false,
        desktopAccountMenuList: {},
        featureFlags: {},
        pageName: 'HOME',
        rewardsClass: '',
        trackOrderClass: '',
        searchClass: '',
        headerMainClass: '',
        menuOverlayClass: '',
        headerCustomClass: '',
        showOverlay: false,
        showBgWhite: false,
        inlineOverlay: false,
        shouldRender: true,
        headerConfigs: {},
        activeCartConfig: {},
        toggleWiggleCart: {},
        scrollPreviousValue: 0,
        productSearchFocus: '',
        locationServiceStoreInfo: {},
        rewardsHoverInfoMessagesTexts: {},
        isFragment: false,
        isNative: false,
        pdpProductOffering: {},
        checkoutProps: {},
        findStoresDetails: {},
        HamburgerSlider: {},
        requestUrl: '',
    };

    static propTypes = {
        headerType: PropTypes.string.isRequired,
        headerId: PropTypes.string,
        accounts: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        isPreview: PropTypes.bool,
        deviceType: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        bagItemCount: PropTypes.number,
        actions: PropTypes.object,
        logoLink: PropTypes.string,
        shopDepartmentMenuInfo: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        accountMenuList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        desktopAccountMenuList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        headerLoaded: PropTypes.bool,
        pageName: PropTypes.string.isRequired,
        rewardsClass: PropTypes.string,
        trackOrderClass: PropTypes.string,
        searchClass: PropTypes.string,
        headerMainClass: PropTypes.string,
        headerCustomClass: PropTypes.string,
        menuOverlayClass: PropTypes.string,
        showOverlay: PropTypes.bool,
        showBgWhite: PropTypes.bool,
        inlineOverlay: PropTypes.bool,
        shouldRender: PropTypes.bool,
        headerConfigs: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        activeCartConfig: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        toggleWiggleCart: PropTypes.object,
        enableKeepMeLoggedIn: PropTypes.bool.isRequired,
        scrollPreviousValue: PropTypes.number,
        productSearchFocus: PropTypes.string,
        locationServiceStoreInfo: PropTypes.object,
        rerenderGallery: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        rewardsHoverInfoMessagesTexts: PropTypes.objectOf(PropTypes.any),
        isFragment: PropTypes.bool,
        isNative: PropTypes.bool,
        pdpProductOffering: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
        checkoutProps: PropTypes.object,
        findStoresDetails: PropTypes.object,
        HamburgerSlider: PropTypes.object,
        requestUrl: PropTypes.string,
    };

    static signinLink = '/signin';

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.getHeaderTheme = this.getHeaderTheme.bind(this);
        this.renderMenuList = this.renderMenuList.bind(this);
        this.menuListClickOption = this.menuListClickOption.bind(this);
        this.isLoggedIn = 1;
        this.state = {
            cartCount: this.props.bagItemCount,
            signedOutActive: false,
            resetMenu: false,
            isUserLoggedIn: null,
            showLoader: false,
            showsignInReminder: this.showsignInReminder(),
            wiggleCart: false,
            headerScroll: 'mainHeaderScroll',
            stickyCustomClass: '',
            displayHover: false,
            isListClicked: false,
            akamaiStoreInfo: {},
            isCamNavHovered: false,
        };
        this.signInClick = this.openSignInSlider.bind(this, false, null, false);
        this.headerAccountMenuClickHandler = this.headerAccountMenuClickHandler.bind(this);
        this.createAccountClick = this.openSignInSlider.bind(this, true, null, false);
        this.authCheckForExpiry = this.authCheckForExpiry.bind(this);
        this.handleNavListCallBack = this.handleNavListCallBack.bind(this);
        this.akamaiStoreCookie = Cookies.load('akamaiStore');
        this.akamaiStoreCookieInfo = this.akamaiStoreCookie
            ? parseJson(this.akamaiStoreCookie)
            : {};
        this.findAStoreLink = _get(
            this.props,
            'preferences.headerStoreHoverConfig.findAStoreLink',
            'https://www.jcpenney.com/findastore'
        );

        // these are related to scroll functionality for sticky header
        this.scrollPreviousValue = 0;
        this.timestamp = new Date();
        this.onSrollTriggerEvents = this.onSrollTriggerEvents.bind(this);
        this.isScrollThresholdMet = this.isScrollThresholdMet.bind(this);
        this.scrollHandler = this.scrollHandler.bind(this);
    }

    componentDidMount() {
        const { isNative, featureFlags } = this.props;
        const enableRewardsWebview = _get(featureFlags, 'enableRewardsWebview', true);
        const isNativeRewardsEnabled = isNative && enableRewardsWebview;

        this.props.actions.showLazy(headerLoad);
        if (!isNativeRewardsEnabled) {
            this.props.actions.accessTimerStart();
            this.authCheckForExpiry();
        }
        if (this.state.showsignInReminder) {
            const toolTipTimeout = _get(
                this.props.preferences,
                'signInSliderWelcomeMessageTimeOut',
                3000
            );
            /* istanbul ignore next */
            setTimeout(() => {
                /* istanbul ignore next */
                this.setState({
                    showsignInReminder: false,
                });
            }, toolTipTimeout);
        }
        const {
            featureFlags: { enableIovationEagerLoading = true, disableStickyHeader },
            preferences: {
                devicefingerPrintConfig: iovationConfig,
                deviceFingerPrintIovation: iovationSrc,
            },
            actions: { onIovationScriptLoadError, onIovationScriptLoadSuccess },
            deviceType: { isTablet },
            enableKeepMeLoggedIn,
        } = this.props;
        /* istanbul ignore next */
        if (enableKeepMeLoggedIn && !enableIovationEagerLoading) {
            iovationLoader(
                iovationSrc,
                iovationConfig,
                onIovationScriptLoadError,
                onIovationScriptLoadSuccess
            );
        }
        /* istanbul ignore next */
        window.handleVendorAction = (actionType, payload, callback) => {
            if (actionType === 'apply_coupon' && payload.couponCode) {
                this.props.actions.applyOtherCoupon(
                    {
                        code: payload.couponCode,
                        serialNumber: payload.serialCode,
                    },
                    callback
                );
            }
        };

        /* istanbul ignore next */
        if (
            !disableStickyHeader &&
            isCSSFeatureSupported(
                '(position: sticky) or (position: -webkit-sticky) or (position: -ms-sticky)'
            ) &&
            !isTablet
        ) {
            window.addEventListener('scroll', this.onSrollTriggerEvents);
        }
    }

    setNormalizedEmail = (existingProfile, normalizedEmail) => {
        const profileValues = {
            profile: {
                ...existingProfile,
                normalizedEmailHash: normalizedEmail,
            },
        };
        Object.assign(window.jcpDLjcp, profileValues);
    };

    componentDidUpdate() {
        const {
            isFragment = false,
            actions: { loadUserStoreLocationInfo },
        } = this.props;
        const { storeName = '' } = this.getPreferredStore();
        const shouldLoadLocationInfo = !storeName && isFragment;
        // loadUserStoreLocationInfo to be called on syndicate sites as the cookie value is stored by browser a bit delayed.
        shouldLoadLocationInfo && loadUserStoreLocationInfo();
    }

    onSrollTriggerEvents() {
        const {
            deviceType: { isDesktop, isMobile },
            featureFlags: { enableOverflowFilters = false },
        } = this.props;
        /* istanbul ignore else */

        // Only for PLP/SRP
        const target = document.getElementById('productlistheight');
        const containerWidthIris = document.getElementById('containerWidthIris');
        let isTopInView = false;
        if (containerWidthIris && target && enableOverflowFilters && isDesktop) {
            const rect = target.getBoundingClientRect();
            const containerHeight = containerWidthIris.getBoundingClientRect();
            isTopInView = rect.top <= containerHeight.height && rect.top >= 0;
        }
        if (enableOverflowFilters && isDesktop && isTopInView) {
            this.setState({ stickyCustomClass: 'stickyRemoveTopPadding' });
        } else if (enableOverflowFilters && isDesktop) {
            this.setState({ stickyCustomClass: 'stickyAddTopPadding' });
        } else {
            this.setState({ stickyCustomClass: null });
        }
        // Only for PLP/SRP

        if (!this.scrollTicking) {
            /* istanbul ignore next */
            window.requestAnimationFrame(() => {
                this.scrollTicking = true;
                const scrollY = window.scrollY || window.pageYOffset;
                let { headerScroll } = this.state;
                if (
                    isMobile
                        ? scrollY > this.scrollPreviousValue &&
                          !(
                              this.scrollPreviousValue === 0 &&
                              scrollY > 0 &&
                              headerScroll !== 'headerScrollDown'
                          )
                        : scrollY > this.scrollPreviousValue
                ) {
                    // scroll down
                    if (scrollY > PRIMARY_HEADER_HEIGHT) {
                        if (!isDesktop) {
                            headerScroll = 'headerScrollDown';
                        } else {
                            headerScroll =
                                enableOverflowFilters && isDesktop
                                    ? 'headerStickyScrollDown'
                                    : null;
                        }
                    }
                    this.scrollHandler({ scrollY, headerScroll });
                } else {
                    // scroll up
                    // remove sticky class for desktop when reaches close to primary header height
                    if (
                        isDesktop &&
                        (scrollY < PRIMARY_HEADER_HEIGHT || scrollY <= 100 || scrollY <= 300)
                    ) {
                        this.scrollHandler({ scrollY, headerScroll: null });
                    } else if (isMobile && scrollY === 0) {
                        this.scrollHandler({ scrollY, headerScroll });
                    } else {
                        headerScroll = 'headerScrollUp';
                        isMobile && scrollY > 100 && this.scrollHandler({ scrollY, headerScroll });
                    }
                    headerScroll = 'headerScrollUp';
                    !isMobile && scrollY > 100 && this.scrollHandler({ scrollY, headerScroll });
                }
                this.scrollPreviousValue = scrollY;
                this.scrollTicking = false;
            });
        }
    }

    scrollHandler({ scrollY, headerScroll }) {
        const {
            galleryRerender,
            actions: { galleryRerenderAction },
            deviceType: { isMobile, isDesktop },
            featureFlags: { enableOverflowFilters = false },
        } = this.props;
        /* istanbul ignore next */
        if (galleryRerender) {
            let scrollClass = null;
            if (isMobile) {
                scrollClass = 'headerScrollUp';
            }
            this.setState({ headerScroll: scrollClass }, () => {
                this.scrollPreviousValue = scrollY;
                this.timestamp = +new Date();
            });
            galleryRerenderAction(false);
            return;
        }
        /* istanbul ignore next */
        if (
            enableOverflowFilters &&
            isDesktop &&
            !headerScroll &&
            this.state.headerScroll !== headerScroll
        ) {
            this.setState({ headerScroll: null }, () => {
                this.scrollPreviousValue = scrollY;
                this.timestamp = +new Date();
            });
        } else if (this.state.headerScroll !== headerScroll && this.isScrollThresholdMet(scrollY)) {
            this.setState({ headerScroll }, () => {
                this.scrollPreviousValue = scrollY;
                this.timestamp = +new Date();
            });
        }
    }

    isScrollThresholdMet(scrollY = 0) {
        const scrollDifference = Math.abs(this.scrollPreviousValue - scrollY);
        const timestampDifference = new Date() - this.timestamp;
        return scrollDifference > SCROLL_THRESHOLD && timestampDifference > TIME_THRESHOLD;
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onSrollTriggerEvents);
    }

    /* ugly code need to redo in r2 */
    shouldComponentUpdate(nextProps, nextState) {
        if (isEqual(nextProps, this.props) && isEqual(nextState, this.state)) {
            return false;
        }
        return true;
    }

    componentWillReceiveProps(nextProps) {
        // this.setState({ cartCount: Cookies.load('ItemCount') });
        this.setState({ cartCount: nextProps.bagItemCount });
        this.getUserStateDetails(nextProps);
        if (nextProps.accounts.userProfile) {
            this.setState({
                showLoader: false,
            });
        } else {
            this.setState({
                showLoader: true,
            });
        }
        const isUserLoggedIn = _get(this.props.accounts, 'userProfile.userState', '0') !== '0';
        const isUserLoggedInNextProps =
            _get(nextProps.accounts, 'userProfile.userState', '0') !== '0';
        const userPreferredStore = this.getPreferredStore();
        const locationServiceStoreId = _get(userPreferredStore, 'storeId', null);
        const { address = '' } = userPreferredStore;
        if (!isEmpty(address)) {
            this.props.actions.updateUserStoreLocationInfo &&
                this.props.actions.updateUserStoreLocationInfo({
                    curbsidePickupIndicator: userPreferredStore.curbsidePickupIndicator || false,
                    distance: userPreferredStore.distance || '',
                    id: userPreferredStore.storeId || '',
                    inputZipCode: userPreferredStore.inputZipCode || '',
                    name: userPreferredStore.storeName || '',
                    phone: userPreferredStore.phone || '',
                    zip: userPreferredStore.zipcode || '',
                    street: userPreferredStore.street || '',
                    city: userPreferredStore.city || '',
                    state: userPreferredStore.state || '',
                    timings: userPreferredStore.timings || '',
                    timingsOverrideMessage: userPreferredStore.timingsOverrideMessage || '',
                });
        }
        // To update store details in cart when a user gets logged in
        if (!isEmpty(this.props.accounts) && !isUserLoggedIn && isUserLoggedInNextProps) {
            if (locationServiceStoreId) {
                this.props.actions.updateStoreOnCartAction &&
                    this.props.actions.updateStoreOnCartAction(locationServiceStoreId);
            } else {
                this.getStoreDetails(true);
            }
        }

        const pageUrl = window && window.location.href;
        if (pageUrl && isGalleryPage(pageUrl)) {
            const urlStoreId = getURLParameterBykey('storeIds', pageUrl);
            if (
                !isEmpty(locationServiceStoreId) &&
                !isEmpty(urlStoreId) &&
                urlStoreId !== locationServiceStoreId
            ) {
                const isSDPUEnabled = getURLParameterBykey('store_availability', pageUrl);

                if (
                    !__SERVER__ &&
                    !document.getElementById('storeGalleryBopisFilter') &&
                    isSDPUEnabled
                ) {
                    const updatedUrl = new URL(pageUrl);
                    updatedUrl.searchParams.set('storeIds', locationServiceStoreId);
                    history.pushState({}, '', updatedUrl);
                }
            }
        }

        try {
            const {
                featureFlags: { enableNormalizedEmailHash = true },
            } = this.props;

            if (
                !isEmpty(nextProps.accounts) &&
                !isUserLoggedIn &&
                isUserLoggedInNextProps &&
                enableNormalizedEmailHash
            ) {
                if (window && window.jcpDLjcp) {
                    const existingProfile = _get(window.jcpDLjcp, 'profile', {});
                    const existingProfileArray = Object.keys(existingProfile);
                    const currEmail = LocalStorage.getData('DP_USER_EMAIL');
                    const trimmedCurrEmail = currEmail ? currEmail.trim().toLowerCase() : '';
                    const currEmailHash = sha256(trimmedCurrEmail);
                    /* istanbul ignore next */
                    if (existingProfileArray.includes('normalizedEmailHash')) {
                        const existingEmailHash = existingProfile.normalizedEmailHash;
                        if (existingEmailHash !== currEmailHash) {
                            this.setNormalizedEmail(existingProfile, currEmailHash);
                        }
                    } else {
                        this.setNormalizedEmail(existingProfile, currEmailHash);
                    }
                }
            }
        } catch (e) {
            console.error('error when saving normalized email hash', e);
        }
    }

    showsignInReminder() {
        let showTooltip = false;
        let isSuccess = false;
        const { featureFlags, deviceType, pageName } = this.props;
        if (
            !__SERVER__ &&
            featureFlags.enableSessionToolTip &&
            deviceType.isDesktop &&
            pageName === 'HOME'
        ) {
            const isSessionActive = LocalStorage.getData('activeSession', true);
            /* istanbul ignore else */
            if (!isSessionActive) {
                showTooltip = true;
                isSuccess = LocalStorage.setData('activeSession', true, true, '', false, 1440);
            }
        }
        return showTooltip && isSuccess;
    }

    getUserStateDetails(nextProps) {
        const getProps = nextProps || this.props;
        const isUserLoggedIn =
            (_get(getProps.accounts, 'userProfile.userState', '0') || '0') !== '0';
        this.setState({
            isUserLoggedIn,
        });
    }

    headerAccountMenuClickHandler() {
        // user_state HOT or WARM (loggedIn): display account nav
        // user_state COLD: display signin slider
        const { isUserLoggedIn } = this.state;
        if (isUserLoggedIn) {
            this.props.actions.openAccountMenuPage();
        } else {
            this.signInClick();
        }
    }

    openSignInSlider(isCreateAccount = false, nextUrl, isLogout = false) {
        const { featureFlags } = this.props;
        const isHideAccountMenu = _get(featureFlags, 'isHideAccountMenuSlider', false);
        const enableRewardsPromoLogin = _get(featureFlags, 'enableRewardsPromoLogin', true);
        const customizeConfig = {
            forceLogin: true,
            skipOnAuthenticationSuccess: true,
            isHideAccountMenu: true,
            postAuthenticationUrl: nextUrl,
            isSliderCustomized: true,
            openCreateAccount: isCreateAccount,
        };
        if (featureFlags.enableSignInSlider) {
            if (!isLogout) {
                isHideAccountMenu
                    ? this.props.actions.customStepUp(customizeConfig)
                    : isGuestUser() && enableRewardsPromoLogin
                    ? this.props.actions.openRewardsPromotionLogin()
                    : this.props.actions.openSignInSlider(isCreateAccount, nextUrl, isLogout);
            } else {
                this.props.actions.showLogoutTooltip();
            }
            // Tell link component to not redirect
            return false;
        }
        if (isCreateAccount) {
            return '/createaccount'; // redirect through Link component to /createaccount
        }
        if (isLogout) {
            return '/sessiontimeout'; // redirect through Link component to /sessiontimeout
        }
        return '/signin'; // redirect through Link component to /signin
    }

    authCheckForExpiry() {
        const { featureFlags } = this.props;
        /* istanbul ignore if */
        if (
            featureFlags?.recognisedStatePageRefresh &&
            TokenProvider.get('DP_USER_STATE') === '1'
        ) {
            if (TokenProvider.get('DP_FULL_ACCESS_EXPIRED') === 'true') {
                this.props.actions.accessTimerAuthCheck();
            }
            setTimeout(() => {
                this.authCheckForExpiry();
            }, 20 * 1000);
        }
    }

    // hpan start
    getHeaderTheme = () => {
        let theme = 'fullHeader';
        if (this.props.headerType === config.headerTypes.MINIMAL_HEADER) {
            theme = 'minimalHeader';
        } else if (this.props.headerType === config.headerTypes.SLIM_HEADER) {
            theme = 'slimHeader';
        }
        return theme;
    };

    handleWrapperClick(event) {
        event.stopPropagation();
    }

    handleNavListCallBack(value) {
        this.setState({ isListClicked: value });
    }

    /* istanbul ignore next */
    searchSelectAnimationRemove = () => {
        if (this.state.headerScroll !== 'headerScrollUp') {
            this.setState({ headerScroll: 'headerScrollUp' });
        }
    };

    renderRewardsInfoInAccountHover = () => {
        const { accounts } = this.props;
        const userName =
            _get(accounts, 'userProfile.firstName', '') || config.accountInfo.myAccountLabel;
        const rewardsDetails = TokenProvider.get('DP_REWARDS_DETAILS') || {};
        return <RewardsInfoOnHover userName={userName} rewardsDetails={rewardsDetails} />;
    };

    renderHoverTooltipData = () => {
        const {
            preferences: { hoverAccountMenuNav },
            accounts,
        } = this.props;
        const { isUserLoggedIn, isListClicked } = this.state;
        const totalCerts = _get(accounts, 'userProfile.totalCerts', 0);
        /* istanbul ignore next */
        isListClicked && this.setState({ isListClicked: false });
        let accountIconWidth = '80px';
        if (this.accountIcon) {
            const position = this.accountIcon.getBoundingClientRect();
            accountIconWidth = position.width;
        }
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        return (
            <div
                className={
                    /* istanbul ignore next */
                    cx('listWrapper', isUserLoggedIn && 'accountLoggedIn')
                }
                onClick={this.handleWrapperClick}
            >
                {!isUserLoggedIn && (
                    <>
                        <Link
                            type="Button"
                            onClick={this.signInClick}
                            data-automation-id="account-signin"
                            buttonType="Primary"
                            size="Md"
                            className={cx('hoverButton')}
                        >
                            Sign In
                        </Link>
                        <Link
                            type="Button"
                            buttonType="Secondary"
                            data-automation-id="account-create-account"
                            onClick={this.createAccountClick}
                            size="Md"
                            className={cx('hoverButton', 'createAccountButton')}
                        >
                            Create Account
                        </Link>
                    </>
                )}
                {
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div
                        className={cx('hoverAction')}
                        onClick={this.headerAccountMenuClickHandler}
                        style={{ width: accountIconWidth }}
                    />
                }
                {isUserLoggedIn && this.renderRewardsInfoInAccountHover()}
                {this.state.isCamNavHovered && (
                    <CAMNavWrapper
                        navConfig={hoverAccountMenuNav}
                        classes={{
                            navContainer: 'headerAccountMenu',
                            hoverContainer: 'hoverAccountWrapper',
                        }}
                        highlightActiveItem={false}
                        navListCallBack={this.handleNavListCallBack}
                        rewardsSpend={totalCerts}
                    />
                )}
            </div>
        );
    };

    /* istanbul ignore next */
    toggleHoverFilters = (isOpen) => {
        if (isOpen) {
            this.setState({
                isCamNavHovered: isOpen,
            });
        }
        this.setState({
            displayHover: !this.state.displayHover,
        });
    };

    /* istanbul ignore next */
    getTrackMyOrder = () => {
        const trackMyOrderPref = this.props.preferences.trackMyOrder || {};
        const userSigninState = User.isUserLoggedIn() ? 'signedin' : 'guest';
        const getTrackOrderLink = () => {
            this.isLoggedIn = User.isUserLoggedIn();
            return this.isLoggedIn ? trackMyOrderPref[userSigninState] : '/orders/trackorders';
        };
        const href = __SERVER__ ? '/orders/trackorders' : getTrackOrderLink();

        const link = (
            <Link
                href={href}
                className={cx('trackMyOrderLink')}
                id="headerTrackMyOrder"
                title="track my orders"
                data-automation-id="trackMyOrderLink"
                onClick={() => {
                    this.props.actions.triggerNavigationClick({
                        linkName: 'top:track my order',
                        isReload: false,
                    });
                    return true; // redirect through Link component to href
                }}
            >
                <div className={cx('trackMy')}>Track My</div>
                <div className={cx('orders')}>Orders</div>
            </Link>
        );
        return __SERVER__ ? <span>{link}</span> : <div>{link}</div>;
    };

    // render rewards icon
    getRewardsIcon = () => {
        const getRewardLink = () => {
            this.isLoggedIn = User.isUserLoggedIn();
            /* istanbul ignore next */
            return !this.isLoggedIn ? '/rewards' : '/rewards/rewards/dashboard';
        };

        /* istanbul ignore next */
        const rewardLink = __SERVER__ ? '/rewards' : getRewardLink();

        const link = (
            <Link
                href={rewardLink}
                className={cx('rewardsIconLink')}
                id="headerRewards"
                title="my rewards"
                data-automation-id="rewardsLink"
            >
                <Icon
                    iconType="svg"
                    className={cx('rewardsIcon')}
                    width="80px"
                    height="40px"
                    viewBox="-5 -5 110 48"
                >
                    <Rewards />
                </Icon>
            </Link>
        );
        /* istanbul ignore next */
        return __SERVER__ ? <span>{link}</span> : <div>{link}</div>;
    };

    // render logo
    getLogo = () => {
        // adding hyperlink only for TOF pages
        const logoLink = this.props.logoLink === 'on' ? '/' : '';
        const { deviceType, featureFlags, preferences } = this.props;
        let iconWidth = '';
        let iconHeight = '';
        let viewBoxValue = '';
        const logoName = _get(preferences, 'assets.logoName', '2019-jcpenney-logo');
        const logoComponent = _get(preferences, 'assets.logoComponent', <Icon2023JcpenneyLogo />);
        const enableNewLogo = _get(featureFlags, 'enableNewLogo', true);
        /* istanbul ignore next */
        if (featureFlags.enableHamburgerMenuLabel && !deviceType.isDesktop) {
            if (enableNewLogo) {
                iconWidth = '97px';
                iconHeight = '32px';
                viewBoxValue = '0 0 97 32';
            } else {
                iconWidth = '103px';
                iconHeight = '24px';
                viewBoxValue = '0 0 103 24';
            }
        } else if (enableNewLogo) {
            iconWidth = '125px';
            iconHeight = '40px';
            viewBoxValue = '0 0 125 40';
        } else {
            iconWidth = '130px';
            iconHeight = '34px';
            viewBoxValue = '0 0 130 34';
        }

        return (
            <Link
                href={logoLink}
                className={cx('logoLink')}
                data-automation-id="jcp-logo"
                title="JCPenney Home"
                id="jcp-logo"
                aria-label="Jcpenney logo navigate to home"
            >
                {!detectisIE() ? (
                    <Icon
                        iconType="svg"
                        width={iconWidth}
                        height={iconHeight}
                        viewBox={viewBoxValue}
                        className={cx('jcpLogo')}
                    >
                        {logoComponent}
                    </Icon>
                ) : (
                    <img src={IEJCPLogo} className={cx('jcpLogo')} alt="jcpLogo" />
                )}
            </Link>
        );
    };

    getCartLink = () => this.props.primaryLinks.cart;

    getGlobalNavParams = () => ({ pageType: this.props.pageName });

    // getPromotionalBannerScrollFlag = () => {
    //     const scrollYPosition = window.scrollY || window.pageYOffset;
    //     const promoBannerScrollFlag = scrollYPosition === 0;
    //     return promoBannerScrollFlag;
    // }

    hydrateOnMount() {
        const globalNavParams = this.getGlobalNavParams();

        if (!__SERVER__) {
            this.props.actions.getUserDetails();
            /* istanbul ignore else */
            if (!this.props.preferences.storeCallNotNeededOnPageLoad) {
                this.props.actions.setMyDefaultStore();
            }
            this.setState({ showLoader: true });
            this.props.actions.updateItemCountAction();

            // Client side rendering for IRIS Global Nav APIs
            if (
                this.props.deviceType.isDesktop &&
                this.props.featureFlags &&
                !this.props.featureFlags.disableVisualNavSSR
            ) {
                this.props.actions.getIrisGlobalNavigationAction(globalNavParams);
            }

            if (this.props.featureFlags && this.props.featureFlags.enableHybridHamburger) {
                // on mount go fetch department list for hybrid hamburger
                this.props.actions.getIrisCategories(globalNavParams);
            }
        }

        if (__SERVER__) {
            /* istanbul ignore next */
            const {
                deviceType: { isDesktop, isBot },
                featureFlags: { enableRenderBotDept = false, disableHeaderSSR = false },
            } = this.props;
            // Server side rendering for IRIS Global Nav APIs ONLY FOR BOT
            /* istanbul ignore else */
            if (!disableHeaderSSR || (isDesktop && isBot && enableRenderBotDept)) {
                this.props.actions.getIrisGlobalNavigationAction(globalNavParams);
            }
        }
        // Update the store details if cookie is available.
        this.getStoreDetails();
    }

    showRewardsInfoInHeader = (accountState) => {
        const {
            accounts: { userProfile = {} } = {},
            rewardsHoverInfoMessagesTexts,
            featureFlags: { enableRewardsPilotProgram = false },
        } = this.props;
        const isEligibleForRewardsPilot =
            (Cookies.load('eligibleForRewardsPilot') && enableRewardsPilotProgram) || false;
        const { firstName = '', userState = '0' } = userProfile || {};
        const isRegisteredUser = userState === '1' || userState === '2';
        const getRewardDetailsFromLocalStorage =
            TokenProvider.get(REWARD_PERSONALIZATION_DP_REWARDS_DETAILS) || {};
        const {
            points: { pointsThreshold = 200, earnedPoints = 0 } = {},
            certificates: { numberOfCerts = 0, totalCertsValue = 0 } = {},
        } = getRewardDetailsFromLocalStorage;
        const {
            earnedPointThreshold = 50,
            earnedPointThresholdPercentage = 0.25,
        } = rewardsHoverInfoMessagesTexts;
        const calculatedEarnedThreshold = isEligibleForRewardsPilot
            ? pointsThreshold * earnedPointThresholdPercentage
            : earnedPointThreshold;
        const handleRewardsInfo =
            numberOfCerts === 0 && totalCertsValue === 0
                ? earnedPoints >= calculatedEarnedThreshold
                    ? `${earnedPoints} / ${pointsThreshold} ${REWARD_PERSONALIZATION_POINTS}`
                    : REWARD_PERSONALIZATION_REWARDS_MEMBER
                : `$${totalCertsValue} ${REWARD_PERSONALIZATION_REWARD_AVAILABLE}`;
        return (
            <div className={dt(['flex', 'flex-col', 'text-left'])}>
                <p
                    data-automation-id="acc-user-name"
                    data-qm-block="true"
                    className={dt(['font-open-sans-semibold', 'text-small', 'text-black'])}
                >
                    {isRegisteredUser ? firstName : REWARD_PERSONALIZATION_MY_ACCOUNT}
                </p>
                <p
                    data-automation-id="acc-info-state"
                    className={dt(['text-xsmall', 'text-black', 'h-4'])}
                >
                    {isRegisteredUser ? handleRewardsInfo : accountState}
                </p>
            </div>
        );
    };

    getAccountIcon = () => {
        const {
            featureFlags: { enableHeaderSigninUpdate = true },
        } = this.props;
        const { isUserLoggedIn } = this.state;
        let icon = <Account />;

        if (enableHeaderSigninUpdate && !isUserLoggedIn) {
            icon = <AccountWithDot />;
        }

        return icon;
    };

    /* Render account icon for signin and my account */
    renderAccountIcon = (accountState) => {
        const {
            featureFlags: { enableHeaderSigninUpdate = true },
            showWelcomeToolTip,
            deviceType: { isDesktop = false },
        } = this.props;
        const showAccountIcon = dt(['flex', 'flex-row']);
        return (
            <div
                className={cx('svg-icon', showAccountIcon)}
                data-automation-id="header_account_block"
            >
                <Icon
                    iconType="svg"
                    automationId="account-icon"
                    className={`${cx('svg-icon')} ${dt(['pr-2'])}`}
                    width="40px"
                    height="35px"
                    viewBox="0 0 40 40"
                    pathClassName={cx('accountFillColor')}
                >
                    {this.getAccountIcon()}
                </Icon>
                {showWelcomeToolTip && isDesktop && (
                    <div className={cx('welcomeToolTip')}>
                        <span
                            data-automation-id="user-name-cached"
                            className={cx('welcomeToolTipText')}
                        >
                            You're Signed Out
                        </span>
                    </div>
                )}
                {isDesktop
                    ? this.showRewardsInfoInHeader(accountState)
                    : !enableHeaderSigninUpdate && (
                          <p data-automation-id="acc-info-state" className={cx('menuText')}>
                              {accountState}
                          </p>
                      )}
            </div>
        );
    };

    shouldRenderTooltip = () => {
        return (
            this.props.featureFlags.enableHeaderSigninUpdate &&
            this.props.deviceType.isDesktop &&
            this.state.isUserLoggedIn
        );
    };

    /* Render account information like  user name and myaccount */
    renderAccountData = (accountState = config.accountInfo.signInLabel) => {
        const { isUserLoggedIn, showsignInReminder, isListClicked } = this.state;

        return (
            <>
                <ThrottleFeatureRender
                    featurePath="enableHeaderHoverMenu"
                    headerName="X-ENABLE-HEADER-HOVER-MENU"
                    cookieName="DP_ENABLE_HEADER_HOVER_MENU"
                >
                    {
                        /* istanbul ignore next */
                        (isEnabled) => (
                            <>
                                {isEnabled ? (
                                    <YodaTooltip
                                        tooltipContentClass={cx('accountTooltipContent', {
                                            newPersonalisedAccount: true,
                                        })}
                                        tooltipBodyClassName={cx(
                                            'accountHoverTooltip',
                                            isUserLoggedIn && 'loggedIn'
                                        )}
                                        renderTooltipContents={this.renderHoverTooltipData()}
                                        triggerEventName="mouseover"
                                        stayOnContentHover
                                        isListClicked={isListClicked}
                                        isAccountHover
                                        callBackFun={this.toggleHoverFilters}
                                        disabled={!this.shouldRenderTooltip()}
                                    >
                                        {this.renderAccountIcon(accountState)}
                                    </YodaTooltip>
                                ) : (
                                    this.renderAccountIcon(accountState)
                                )}
                            </>
                        )
                    }
                </ThrottleFeatureRender>
                {
                    /* istanbul ignore next */
                    showsignInReminder && !isUserLoggedIn && (
                        <div
                            data-automation-id="default-account-username"
                            className={cx('welcomeToolTip')}
                        >
                            <span
                                data-automation-id="user-name-cached"
                                className={cx('welcomeToolTipText', 'welcomeGuestToolTip')}
                            >
                                <span className={cx('startEarningRewards')}>
                                    Start Earning Rewards!
                                </span>
                                <>
                                    <Link
                                        type="Button"
                                        onClick={this.signInClick}
                                        automationId="account-signin"
                                        buttonType="Primary"
                                        size="Mini"
                                        className={cx('sigInButton', 'welcomeSignInBtn')}
                                    >
                                        Sign In
                                    </Link>
                                    <Link
                                        type="button"
                                        data-automation-id="acc-signup-register-btn"
                                        onClick={this.createAccountClick}
                                    >
                                        <a
                                            data-automation-id="acc-signup-register-anchor"
                                            className={cx('registerLink')}
                                        >
                                            {' '}
                                            {'create account'}{' '}
                                        </a>
                                    </Link>
                                </>
                            </span>
                        </div>
                    )
                }
            </>
        );
    };

    renderAccoundInformation = () => {
        const { isUserLoggedIn } = this.state;

        if (isUserLoggedIn) {
            const myAccountLabel =
                _get(this.props, 'accounts.userProfile.firstName', '') ||
                config.accountInfo.myAccountLabel;
            return this.renderAccountData(myAccountLabel);
        }
        return this.renderAccountData();
    };

    renderTooltipData() {
        return this.state.showLoader ? (
            <Loader automationId="account-loader" />
        ) : (
            this.renderAccountTooltipData()
        );
    }

    prepareMenuList(attribute = 'guest') {
        let accountMenuList = this.props.accountMenuList[attribute];
        if (this.props.deviceType.isDesktop) {
            const desktopAccountMenuList = this.props.desktopAccountMenuList[attribute] || [];
            const destMenu = _keyBy(accountMenuList, 'name');
            const srcMenu = _keyBy(desktopAccountMenuList, 'name');
            accountMenuList = _values(_merge({}, destMenu, srcMenu));
        }
        return filter(accountMenuList, (item) => item.isDisplayRequiredInAccountMenu);
    }

    renderAccountTooltipData() {
        const { isUserLoggedIn } = this.state;
        const totalCerts = _get(this.props.accounts, 'userProfile.totalCerts', 0);

        let source;
        let styleClass;
        let showButtonANdLink = false;
        const rewardsStatus = _get(this.props.accounts, 'userProfile.rewardsStatus');
        const rewardsCount = rewardsStatus ? totalCerts : 0;

        if (isUserLoggedIn) {
            source = this.prepareMenuList('signedin');
            styleClass = totalCerts || totalCerts === 0 ? 'signedInWithCerts' : 'signedInUser';
        } else {
            source = this.prepareMenuList('guest');
            styleClass = 'signedOutUser';
            showButtonANdLink = true;
        }
        return (
            <div data-automation-id="headerAccountTooltip" id="headerAccount">
                {showButtonANdLink ? (
                    <>
                        <Link
                            type="Button"
                            onClick={this.signInClick}
                            automationId="account-signin"
                            buttonType="Primary"
                            size="Lg"
                            className={cx('sigInButton')}
                        >
                            Sign In
                        </Link>
                        <span data-automation-id="acc-signup-text" className={cx('newCustomer')}>
                            {'New Customer? '}
                        </span>
                        <Link
                            type="button"
                            data-automation-id="acc-signup-register-btn"
                            onClick={this.createAccountClick}
                        >
                            <a
                                data-automation-id="acc-signup-register-anchor"
                                className={cx('registerLink')}
                            >
                                {' '}
                                {'Register Here'}{' '}
                            </a>
                        </Link>
                    </>
                ) : null}
                <List
                    datasource={source}
                    direction="Vertical"
                    listBodyClass={cx('list-class')}
                    listStyleClass={cx('list-ul-class')}
                    itemStyleClass={cx('list-item-class', styleClass)}
                    childRenderer={this.renderMenuList}
                    spacing="None"
                    automationId="at-error-helpfullinks-renderer"
                />
                {totalCerts ? (
                    <Button
                        type="button"
                        automationId="rewards-redeem"
                        buttonType="Primary"
                        size="Lg"
                        className={cx('redeemText')}
                    >
                        <span
                            data-automation-id="acc-info-rewards-block"
                            className={cx('redeemCertsWrapper')}
                        >
                            <span data-automation-id="rewardsAmount" className={cx('dollerValue')}>
                                $
                            </span>
                            <span data-automation-id="rewards-count">{rewardsCount}</span>
                        </span>
                        Redeem Your Rewards
                    </Button>
                ) : null}
            </div>
        );
    }

    menuListClickOption = (nextUrl) => {
        const { featureFlags } = this.props;
        /* istanbul ignore next */
        if (!__SERVER__) {
            if (featureFlags.enableSignInSlider) {
                if (featureFlags.enableLogoutSlider && nextUrl === '/sessiontimeout') {
                    return this.openSignInSlider(false, undefined, true);
                }
                if (nextUrl === '/sessiontimeout') {
                    return nextUrl;
                }
                if (LocalStorage.getData('DP_USER_STATE') === '1') {
                    return true; // redirect through Link component
                }
                return this.openSignInSlider(false, nextUrl);
            }
            return nextUrl; // redirect through Link component
        }
        /* istanbul ignore next */
        return false; // tell Link component not to redirect
    };

    renderMenuList = (item) => {
        const rewardsCount =
            this.props.accounts &&
            this.props.accounts.userProfile &&
            this.props.accounts.userProfile.rewardsStatus
                ? this.props.accounts.userProfile.totalCerts
                : 0;
        /* const savedItemCount = this.props.account &&
                                this.pros.accounts.userProfile ?
                                this.props.accounts.userProfile.savedItemsCount : 0; */
        const linksForLabels = item.link;
        const sliderNeeded = item.isSliderNeeded || false;

        const totalCerts =
            this.props.accounts &&
            this.props.accounts.userProfile &&
            this.props.accounts.userProfile.totalCerts &&
            this.props.accounts.userProfile.totalCerts !== '0'
                ? this.props.accounts.userProfile.totalCerts
                : '';

        if (sliderNeeded) {
            return (
                <Link
                    type="button"
                    className={cx('links')}
                    onClick={() => this.menuListClickOption(linksForLabels)}
                >
                    {item.name}
                    {totalCerts && item.displayCount === 'rewards' && (
                        <span className={cx('rewardscount')}>{`(${rewardsCount})`}</span>
                    )}
                </Link>
            );
        }

        return (
            <Link href={linksForLabels} className={cx('links')} data-automation-id="item-name">
                {item.name}
                {totalCerts && item.displayCount === 'rewards' && (
                    <span
                        data-automation-id="rewards-count"
                        className={cx('rewardscount')}
                    >{`(${rewardsCount})`}</span>
                )}
            </Link>
        );

        /* {(totalCerts && item.displayCount === 'savedItems') && <span className={cx('rewardscount')}>{`(${savedItemCount})`}</span>} */
    };

    /* istanbul ignore next */
    toggleHoverStores = () => {
        if (this.akamaiStoreCookieInfo !== this.state.akamaiStoreInfo) {
            this.setState({ akamaiStoreInfo: this.akamaiStoreCookieInfo });
        }
    };

    getPreferredStore = () => {
        const { locationServiceStoreInfo = {} } = this.props;
        let returnValue = '';
        const userPreferredStoreCookie = Cookies.load(
            LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE,
            true
        );
        returnValue = !isEmpty(userPreferredStoreCookie) ? locationServiceStoreInfo : {};

        return returnValue;
    };

    /* istanbul ignore next */
    renderHoverStoreTooltipData = () => {
        const { isUserLoggedIn } = this.state;
        return (
            <StoreInfo
                locationStoreInfo={this.getPreferredStore()}
                isUserLoggedIn={isUserLoggedIn}
                triggerStoreSelectionSlider={this.triggerStoreSelectionSlider}
            />
        );
    };

    analyticsForNavigationClick(localTitle) {
        this.props.actions.triggerNavigationClick({
            linkName: localTitle,
            isReload: false,
        });
    }

    /* istanbul ignore next */
    findAStoreClickHandler = () => {
        this.analyticsForNavigationClick('top:findastore');
        window.location.href = this.findAStoreLink;
    };

    triggerStoreSelectionSlider = (e) => {
        e?.stopPropagation && e.stopPropagation();
        const {
            actions,
            preferences: { defaultRadius = 25 },
            pdpProductOffering: { actualSkuId = '', promise: { skuDeliveryOptions } = {} },
            findStoresDetails: { zipCode = '' },
            checkoutProps: { findStores: { selectActionCallback } = {} } = {},
        } = this.props;
        const { inputZipCode = '' } = this.getPreferredStore();

        this.onSliderSelectStore = (sliderCallBack = {}) => {
            const pageUrl = window?.location?.href;

            /* istanbul ignore next */
            if (isCartPage(pageUrl)) {
                actions.selectStore({ ...sliderCallBack, selectActionCallback });
            }

            /* istanbul ignore next */
            if (pageUrl && isGalleryPage(pageUrl)) {
                const isSDPUEnabled = getURLParameterBykey('store_availability', pageUrl);
                if (isSDPUEnabled) {
                    const { id = '' } = sliderCallBack?.storeDetails || {};
                    window.location.href = updateQueryStringParameter(pageUrl, 'storeIds', id);
                }
            }
            /* istanbul ignore else */
            if (actualSkuId) {
                actions.selectStore({ ...sliderCallBack, fromMiniPDP: false });
            }
            const keyValue = SessionStorage.getData('setBopisDefaultStore', true);
            /* istanbul ignore if */
            if (isEmpty(keyValue)) {
                actions.triggerBopisDefaultStoreChange();
                SessionStorage.setData('setBopisDefaultStore', true, true);
            } else actions.triggerBopisPickupStoreChange();
        };

        const searchZip = inputZipCode || zipCode;
        const parentSkuId = skuDeliveryOptions?.[0]?.skuId;
        /* istanbul ignore next */
        if (searchZip) {
            const payload = {
                zipCode: searchZip,
                miles: defaultRadius,
                pageSize: FIND_STORES_PAGE_SIZE, // number of stores to request per request
                showAvailable: false,
                skus: parentSkuId || actualSkuId || '',
            };
            actions.findStores(payload);
        }
        actions.setFindStoreSliderProps({
            findStoresAction: actions.findStores,
            enableSlimFindStores: true,
            enableProductCardImage: true,
            hideContentScroll: true,
            isMounted: actions.openSlidePanel,
            selectStoreAction: this.onSliderSelectStore,
            skuSelected: actualSkuId || '',
        });
        actions.openSlidePanel();
    };

    /**
     * On store hover, Geo located store information will be displayed.
     */
    renderStoreInfo = () => {
        const { isFragment } = this.props;
        const { storeName, city, state } = this.getPreferredStore();
        return (
            <>
                <YodaTooltipLazy
                    tooltipContentClass={cx('storeTooltipContent', 'storeIconWithNameTooltip')}
                    tooltipTextClass={cx('storeHoverTooltip', 'new-store-service-block')}
                    renderTooltipContents={this.renderHoverStoreTooltipData()}
                    triggerEventName={storeName ? 'mouseover' : ''}
                    tooltipBodyClassName={cx('storeContainer')}
                    stayOnContentHover
                    callBackFun={this.toggleHoverStores}
                    shouldToggleOnMouseOver
                    tooltipType="link"
                >
                    <div
                        className={cx('svg-icon', 'ctaList', 'storeBlock')}
                        data-automation-id="header_store_block"
                        onClick={this.findAStoreClickHandler}
                    >
                        <div className={dt(['flex', 'justify-center'])}>
                            <YDTIcon width="28px" height="28px">
                                <StoreLine />
                            </YDTIcon>
                        </div>
                        {/* iconText to be hidden when flag is false in order to capture activity map */}
                        {<p className={`${cx('menuText', 'iconText')} ${dt(['!pl-0'])}`}>Stores</p>}
                    </div>
                    {
                        /* Displaying store name on header was implemented
                        as a part of enabling User Preffered Store On Header Story */

                        <div className={dt(['pl-2', 'pb-px'])}>
                            <Button
                                size="Sm"
                                type="linkbutton"
                                linkUrl="javascript:void(0)"
                                buttonType="Text"
                                className={cx('storeNameBlock')}
                                onClick={this.findAStoreClickHandler}
                            >
                                <div
                                    className={`${cx('storeNameOnHeader')} ${dt([
                                        'whitespace-nowrap',
                                    ])}`}
                                >
                                    {storeName || headerStore.NO_STORE_LOCATED}
                                </div>
                                {city || state ? (
                                    <span className={cx('storeAddress')}>
                                        {city && `${city}`}
                                        {city && state && `, `}
                                        {state && `${state}`}
                                    </span>
                                ) : (
                                    ''
                                )}
                            </Button>
                            {!isFragment && isEmpty(storeName) ? (
                                <Button
                                    size="Sm"
                                    type="linkbutton"
                                    linkUrl="javascript:void(0)"
                                    buttonType="Link"
                                    className={`${cx('storeInformationTxt')} ${dt([
                                        'font-normal',
                                        'bg-white',
                                        'text-xsmall',
                                        'flex',
                                        'leading-4',
                                        'h-4',
                                    ])}`}
                                    onClick={this.triggerStoreSelectionSlider}
                                >
                                    <span className={dt(['text-xsmall'])}>
                                        {FIND_STORES_SELECT_A_STORE_HEADER_TEXT}
                                    </span>
                                </Button>
                            ) : (
                                ''
                            )}
                        </div>
                    }
                </YodaTooltipLazy>
            </>
        );
    };

    renderAccount = () => {
        const {
            featureFlags: { enableHeaderSigninUpdate = false },
            isPreview,
        } = this.props;
        const styleTooltip = this.state.showLoader
            ? cx('tooltipMenuWrapper', 'tooltipWithLoader')
            : cx('tooltipMenuWrapper');

        // todo we have to revisit the approach. There way when we have 5 small components lazy loaded right after the page loaded is not proper way
        // What i see here is that we have 3 components that should not be part of app.js at all for mobile and should work for desktop only. What im thinking that we need to have 2 app.js entries:
        //
        //     app.mobile.js
        // app.desktop.js
        //
        // and using DefinePlugin just create different bundles.
        //
        //     after that we would load on client only Html.jsx with needed app.mobile.js or app.desktop.js file.
        //
        //     [~prajappa][~vvozniak][~iborovts] need your input here. What do you think?

        const getAccountDetails = () => (
            <div
                className={`${styles.accountMenu} ${cx(
                    enableHeaderSigninUpdate ? 'headerUpdate' : ''
                )} ${cx(isPreview ? 'isPreview' : '')}`}
                data-automation-id="account-tooltip"
                ref={(node) => {
                    this.wrapperRef = node;
                }}
            >
                {this.renderAccoundInformation()}
            </div>
        );
        let item = null;
        if (__SERVER__) {
            item = getAccountDetails();
        } else {
            item = this.props.headerLoaded && (
                <ThrottleFeatureRender
                    featurePath="enableHeaderAccountMenuSlider"
                    headerName="X-HEADER-ACCOUNT-MENU-SLIDER"
                    cookieName="DP_HEADER_ACCOUNT_MENU_SLIDER"
                >
                    {(isEnabled) => (
                        <>
                            {isEnabled && (
                                // Div is for IE11 changes for event bubbling
                                // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                                <div onClick={this.headerAccountMenuClickHandler}>
                                    {getAccountDetails()}
                                </div>
                            )}
                            {!isEnabled && (
                                <YodaTooltipLazy
                                    tooltipContentClass={styleTooltip}
                                    tooltipBodyClassName={cx('headerTooltipWrapper')}
                                    renderTooltipContents={this.renderTooltipData()}
                                    triggerEventName="click"
                                    direction="Bottomright"
                                    tooltipPlacement="Right"
                                    disabled={!this.shouldRenderTooltip()}
                                >
                                    {getAccountDetails()}
                                </YodaTooltipLazy>
                            )}
                        </>
                    )}
                </ThrottleFeatureRender>
            );
        }
        return item;
    };

    renderHamburger = () => {
        /* istanbul ignore next */
        const {
            featureFlags: { enableHamburgerMenuLabel = false, enableRenderBotDept = false } = {},
            deviceType: { isDesktop, isBot = false },
            pageName = 'HOME',
        } = this.props;
        const botHamburger =
            isBot && enableRenderBotDept ? (
                <MobileBotHamburger pageName={pageName} />
            ) : (
                <HamburgerPlaceHolder
                    enableHamburgerMenuLabel={enableHamburgerMenuLabel}
                    isBot={isBot}
                    enableRenderBotDept={enableRenderBotDept}
                    HamburgerSlider={this.props.HamburgerSlider}
                    displayHamburgerSlider={this.props.actions.displayHamburgerSlider}
                    triggerStoreSelectionSlider={this.triggerStoreSelectionSlider}
                    getPreferredStore={this.getPreferredStore}
                />
            );
        return !isDesktop ? botHamburger : null;
    };

    renderStickyTimer() {
        let stickyTimerHtml = false;
        /* istanbul ignore next */
        if (detectisIE()) {
            stickyTimerHtml = this.props.featureFlags.enableStickyTimer &&
                this.props.featureFlags.enableStickyTimerForIE && (
                    <StickyTimer renderFromHeader mountOnServer />
                );
        } else {
            stickyTimerHtml = this.props.featureFlags.enableStickyTimer && (
                <StickyTimer renderFromHeader mountOnServer />
            );
        }
        return stickyTimerHtml;
    }

    getStoreDetails(shouldRefreshStore = false) {
        /* istanbul ignore next */
        const { featureFlags = {} } = this.props;
        if (featureFlags.enableLocationService) {
            this.props.actions.loadUserStoreLocationInfo(shouldRefreshStore);
        }
    }

    /* istanbul ignore next */
    validateHomePage = () => {
        if (!__SERVER__) {
            return window.location.pathname === '/';
        }
        return false;
    };

    // For VisNav Integration
    /* istanbul ignore next */
    visNavRender = (dataItem) => {
        const { title, image, links, openInNewTab } = dataItem;
        return (
            <div className={cx('headerVisualNavComponent')}>
                <a
                    className={cx('visualNavLink')}
                    href={links}
                    aria-label={title}
                    data-link-href={links}
                    target={openInNewTab ? '_blank' : '_self'}
                    rel="noreferrer"
                    // onClick={() => this.handleClickVisualNavItemLink(dataItem)}
                >
                    {image && (
                        <div className={cx('imgBlock')}>
                            <img
                                className={cx('vizNavImage')}
                                src={image}
                                alt={title}
                                title={title}
                            />
                        </div>
                    )}
                    <div className={cx('title')}>{title}</div>
                </a>
            </div>
        );
    };
    // For Vis nav integration Ends

    // hpan Ends
    render() {
        /* istanbul ignore next */
        const {
            shouldRender,
            deviceType,
            featureFlags = {},
            headerConfigs = {},
            showOverlay,
            showBgWhite,
            inlineOverlay,
            activeCartConfig,
            toggleWiggleCart,
            isNative,
            rewardsErrorObj,
        } = this.props;
        const { enableHeaderSigninUpdate = false } = featureFlags;
        const { isUserLoggedIn } = this.state;
        const {
            hideAccountSection = false,
            // hideRewardsSection = false, /* commenting as per new Header Design */
            // hideTrackMyOrder = false, /* commenting as per new Header Design */
            hideStoresBlock = false,
        } = headerConfigs.flags || {};
        /* istanbul ignore if */
        if (!shouldRender) {
            return this.renderStickyTimer();
        }
        const {
            isDesktop = false,
            isMobile,
            isTablet,
            isBot,
        } = this.props.deviceType; /* commenting as per new Header Design */
        /* istanbul ignore next */
        const disableTopMini =
            featureFlags.disableAccessViewFromHeader && !featureFlags.promoBanner;
        const enableExitPosClient = !__SERVER__ && featureFlags.enableExitPos;
        const enableActiveCart =
            !__SERVER__ && featureFlags.enableActiveCartReminder && !isEmpty(activeCartConfig);
        /* istanbul ignore next */
        const cartIcon = this.state.cartCount > 99 ? 'cart-large' : 'cart-line';
        const cartIconComponent = this.state.cartCount > 99 ? <CartLarge /> : <CartLine />;
        const cartStyleName = cx('bagBlock', 'ctaList', 'cartBag');
        const moreCartStyles = this.state.cartCount > 99 && 'moreCart';
        /* istanbul ignore next */
        const flyoutMenuClass = featureFlags.enableFlyoutMenu ? 'flyoutMenu' : 'secondaryMenu';
        const flyoutMenuDeviceClass = isDesktop ? 'menuDesktop' : 'menuNotDesktop';
        const headerDesktopClass = isDesktop ? 'headerDesktop' : 'headerNotDesktop';
        const headerSearchDesktopClass = isDesktop
            ? 'headerSearchDesktop'
            : 'headerSearchNotDesktop';
        /* istanbul ignore next */
        const headerTabletClass = isTablet ? 'headerTablet' : 'headerNotTablet';
        const headerMobileClass = isMobile ? 'headerMobile' : 'hederNotMobile';
        const headerBotClass = isBot ? 'headerBot' : 'headerNotBot';
        const { hideVisNavSlider = true } = featureFlags;
        /* istanbul ignore next */
        const newSiteHeightClass = !featureFlags.promoBanner ? 'noHeight' : '';
        const disableStickyHeaderClass = featureFlags.disableStickyHeader
            ? 'disableStickyHeader'
            : '';
        /* istanbul ignore next */
        const bgWhite = showBgWhite ? cx('makeBgWhite') : cx('');
        const headerOverStyle = dt([
            'absolute',
            'hidden',
            'top-0',
            'left-0',
            'bottom-0',
            'right-0',
            'w-full',
            'h-full',
            'bg-black',
            'bg-opacity-60',
            'sm:z-[8]',
        ]);
        const showAccountBlock =
            (!hideAccountSection && isDesktop) ||
            (enableHeaderSigninUpdate && (isMobile || isTablet) && !isUserLoggedIn);
        return (
            <>
                {/* render Chat button as hidden and ensighten  witll pick dom and do further processing */}
                {!this.props.isFragment && <ThirdPartyServiceButton name="chat" />}
                {/* For syndicate site
                    TODO: #TJ evaluate removing the one in <Layout/> */}
                {
                    /* istanbul ignore next */
                    inlineOverlay && showOverlay && (
                        <div className={cx('inlineOverlay', this.props.menuOverlayClass || '')} />
                    )
                }
                <div id="headerOverlay" className={cx('headerOverlay', headerOverStyle)} />
                {/* Header Primary */}
                {rewardsErrorObj?.rewardsError && (
                    <div className={dt(['bg-warning-border', 'px-6', 'py-3', 'lg:text-center'])}>
                        <div className={dt(['relative'])}>
                            <div
                                className={dt([
                                    'smMd:absolute',
                                    'smMd:left-0',
                                    'smMd:top-0',
                                    'lg:inline',
                                ])}
                            >
                                <Icon iconType="svg" width="24" height="24" viewBox="0 2 28 28">
                                    <Warning />
                                </Icon>
                            </div>
                            <p className={dt(['text-medium', 'ml-8', 'lg:inline', 'lg:ml-2'])}>
                                {rewardsErrorObj?.rewardsErrorText}
                            </p>
                        </div>
                    </div>
                )}
                {!disableTopMini && (
                    <TopMiniNav
                        deviceType={this.props.deviceType}
                        isNewHeader
                        enableSitePromo={featureFlags.promoBanner}
                        disableAccessViewFromHeader={featureFlags.disableAccessViewFromHeader}
                    />
                )}
                {/* Header Primary Ends */}

                {!isNative && (
                    <header
                        data-automation-id="headerBlock"
                        id={this.props.headerId || 'header'} // for analytics activity map
                        className={cx(
                            'headerBlock',
                            'newHeader',
                            'tailwindHeader',
                            this.props.headerCustomClass,
                            headerMobileClass,
                            headerTabletClass,
                            headerDesktopClass,
                            headerSearchDesktopClass,
                            headerBotClass,
                            this.state.headerScroll,
                            this.state.stickyCustomClass,
                            this.getHeaderTheme(),
                            disableStickyHeaderClass,
                            bgWhite
                        )}
                    >
                        <div
                            data-automation-id="mainHeaderBlock"
                            className={cx(
                                'mainHeaderBlock',
                                this.props.productSearchFocus,
                                newSiteHeightClass
                            )}
                        >
                            <FetchRegion />
                            {/* Header Secondary */}
                            <div
                                data-automation-id="headerMainBlock"
                                className={cx('mainMenu', this.props.headerMainClass || '')}
                            >
                                <ul className={cx('headerBlockList', 'containerWidth')}>
                                    {/* iconTextWrapper class will have to inject only if the menu feature flag enabled */}
                                    <li
                                        className={cx(
                                            'headerList',
                                            'menuBlock',
                                            !deviceType.isDesktop && 'iconTextPadding',
                                            featureFlags.enableHamburgerMenuLabel
                                                ? 'iconTextWrapper'
                                                : ''
                                        )}
                                    >
                                        <div
                                            id="hamburgerBlock"
                                            data-automation-id="hamburgerBlock"
                                            className={styles.headerNavigation}
                                        >
                                            {this.renderHamburger()}
                                        </div>
                                    </li>
                                    {/* logoCenter class will have to inject only if the menu feature flag enabled */}
                                    <li
                                        id="logoBlock"
                                        data-automation-id="logoBlock"
                                        className={cx('headerList', 'logoBlock')}
                                    >
                                        <div className={cx('logoMainBlock')}>{this.getLogo()}</div>
                                        {!isDesktop && (
                                            <div
                                                id="mobSearchIcon"
                                                data-automation-id="mobSearchIcon"
                                                className={cx(
                                                    'mobSearchIcon',
                                                    this.props.productSearchFocus
                                                )}
                                            />
                                        )}
                                    </li>
                                    <li
                                        id="searchBlock"
                                        data-automation-id="searchBlock"
                                        className={cx(
                                            'headerList',
                                            'searchBlock',
                                            this.props.searchClass || '',
                                            this.props.productSearchFocus
                                        )}
                                    >
                                        <ProductSearch
                                            searchSelectAnimationRemove={
                                                this.searchSelectAnimationRemove
                                            }
                                            automationId="tablet-search-block"
                                            productSearchContainerCustomClass={cx(
                                                'headerSearchContainerCustomClass'
                                            )}
                                            productSearchIconCustomClass={cx(
                                                'headerSearchIconCustomClass'
                                            )}
                                            productSearchInputCustomClass={cx(
                                                'headerSearchInputCustomClass'
                                            )}
                                            productResetIconCustomClass={cx(
                                                'headerResetIconCustomClass'
                                            )}
                                            headerScroll={this.state.headerScroll}
                                            isUserLoggedIn={this.state.isUserLoggedIn}
                                        />
                                    </li>
                                    {/* No Border 'noRgtBorder' only if the hamburger menu feature flag enabled */}
                                    <li
                                        id="ctaBlock"
                                        data-automation-id="ctaBlock"
                                        className={`${cx(
                                            'headerList',
                                            'ctaBlock',
                                            enableHeaderSigninUpdate ? 'headerUpdate' : ''
                                        )} ${dt(['float-right'])}`}
                                    >
                                        {/* {!hideRewardsSection ? <div className={cx('svg-icon', 'ctaList', this.props.rewardsClass || '', 'rewardsBlock', (featureFlags.enableHamburgerMenuLabel ? 'noRgtBorder' : ''))}>
                                        {this.getRewardsIcon()}
                                    </div> : ''} */}
                                        {/* No Border 'noRgtBorder' only if the hamburger menu feature flag enabled */}
                                        {/* {!hideAccountSection ? <div className={cx('accBlock', 'ctaList', (featureFlags.enableHamburgerMenuLabel ? 'noRgtBorder' : ''))}>
                                        {this.renderAccount()}
                                    </div> : '' } */}
                                        {/* {(isDesktop && !hideTrackMyOrder) ? (
                                        <div className={cx('ctaList', this.props.trackOrderClass || '', 'trackMyOrderBlock')}>
                                            {this.getTrackMyOrder()}
                                        </div>
                                    ) : ''} */}
                                        {/* Have to do the changes as per Functionality */}
                                        {/* Stores Block Need to put condition for Mobile to Hide */}
                                        {!hideStoresBlock && isDesktop ? (
                                            <div
                                                id="findStore"
                                                data-automation-id="findStoreBlock"
                                                className={cx(
                                                    'svg-icon',
                                                    'ctaList',
                                                    'findStoreBlock'
                                                )}
                                            >
                                                {this.renderStoreInfo()}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {/* Stores Block Ends Need to put condition for Mobile to Hide */}
                                        {/* Account Block Need to put condition for Mobile to Hide */}
                                        {showAccountBlock ? (
                                            <div
                                                id="accountBlock"
                                                data-automation-id="accountBlock"
                                                className={cx(
                                                    'accBlock',
                                                    'ctaList',
                                                    featureFlags.enableHamburgerMenuLabel &&
                                                        !featureFlags.enableHeaderSigninUpdate
                                                        ? 'noRgtBorder'
                                                        : ''
                                                )}
                                                ref={(node) => {
                                                    this.accountIcon = node;
                                                }}
                                            >
                                                {this.renderAccount()}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {/* Account Block Ends Need to put condition for Mobile to Hide */}
                                        {/* Have to do the changes as per Functionality Ends */}
                                        <CartWrapper
                                            cartStyleName={cartStyleName}
                                            enableActiveCart={enableActiveCart}
                                            activeCartConfig={activeCartConfig}
                                            toggleWiggleCart={toggleWiggleCart}
                                        >
                                            <Link
                                                href={this.getCartLink()}
                                                className={cx('headerIcon', 'bagIcon')}
                                                data-automation-id="bagLink"
                                                title="my cart"
                                                aria-label={`Bag ${this.state.cartCount} - items`}
                                                rel="noopener noreferrer"
                                            >
                                                <div className={cx('svg-icon')}>
                                                    <Icon
                                                        iconType="svg"
                                                        className={cx('svg-icon')}
                                                        width="40px"
                                                        height="40px"
                                                        viewBox="0 0 40 40"
                                                        pathClassName={cx('cartFillColor')}
                                                    >
                                                        {cartIconComponent}
                                                    </Icon>
                                                </div>
                                                {this.state.cartCount > 0 ? (
                                                    <span
                                                        id="orderQuantity"
                                                        data-automation-id="item-bag-count"
                                                        className={cx('cartCount', moreCartStyles)}
                                                    >
                                                        {this.state.cartCount}
                                                    </span>
                                                ) : null}
                                            </Link>
                                        </CartWrapper>
                                        {
                                            /* istanbul ignore next */
                                            enableExitPosClient &&
                                            (Cookies.load('x-mode') ||
                                                Cookies.load('Fusion-Platform')) ? (
                                                <div className={cx('ctaList')}>
                                                    <ExitPosLazy />
                                                </div>
                                            ) : (
                                                ''
                                            )
                                        }
                                    </li>
                                </ul>
                            </div>
                            {/* Header Secondary Ends */}
                            <div
                                id="flyoutMenuBlock"
                                data-automation-id="flyoutMenuBlock"
                                className={cx(
                                    'menuBlock',
                                    this.props.productSearchFocus,
                                    flyoutMenuDeviceClass,
                                    flyoutMenuClass
                                )}
                            >
                                {/* For Flyout menu */}
                                {this.props.deviceType.isDesktop && (
                                    <SecondaryNavigationPanel
                                        deviceType={deviceType}
                                        singleFlyoutSyndicateExperience={!!inlineOverlay}
                                    />
                                )}
                                {/* For Flyout menu ends */}
                            </div>
                        </div>
                    </header>
                )}
                <div
                    id="timerVisNavBlock"
                    data-automation-id="timerVisNavBlock"
                    className={cx('timerVisNavBlock')}
                >
                    {/* Placeholder for VisNavBlock */}
                    {
                        /* istanbul ignore next */
                        !this.props.deviceType.isDesktop &&
                            !isEmpty(this.props.hambergurMenu) &&
                            this.validateHomePage() &&
                            !hideVisNavSlider && (
                                <List
                                    datasource={this.props.hambergurMenu}
                                    childRenderer={this.visNavRender}
                                    automationId="render-item-content"
                                    spacing="None"
                                    listMainCustomClass={cx('renderListMainCustomClass')}
                                    listStyleClass={cx('renderListStyle')}
                                    listBodyClass={cx('renderListBody')}
                                    itemStyleClass={cx('renderItemStyle')}
                                    direction="Horizontal"
                                />
                            )
                    }
                    {/* Placeholder for VisNavBlock Ends */}
                    {this.renderStickyTimer()}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { context } = state;
    const isPreview = context && context.isPreview ? context.isPreview : false;
    const deviceType = context && context.deviceType ? context.deviceType : {};
    const preferences = context && context.preferences ? context.preferences : {};
    /* istanbul ignore next */
    const primaryLinks = preferences && preferences.primaryLinks ? preferences.primaryLinks : {};
    const desktopAccountMenuList = _get(context, 'preferences.desktop.hamburger', {});
    const featureFlags = _get(context, 'featureFlags', {});
    const requestUrl = _get(context, 'requestUrl', '');
    const showWelcomeToolTip =
        state.signInSliderDetails && state.signInSliderDetails.showWelcomeToolTip;
    const headerConfigs = preferences && preferences.headerConfigs;
    const activeCartConfig = _get(state.context, 'preferences.activeCartReminderConfig', {});
    const isFragment = _get(state.context, 'isFragment', false);
    const isNative = _get(state.context, 'isNative', false);
    const rewardsHoverInfoMessagesTexts = _get(
        state,
        'context.messagesTexts.rewardsInfoOnHover',
        {}
    );
    return {
        signedOut: state.session && state.session.signedOut,
        isPreview,
        deviceType,
        preferences,
        headerConfigs,
        bagItemCount: state.bagItemCount,
        primaryLinks: deviceType.isDesktop ? primaryLinks.desktop : primaryLinks.device,
        accountMenuList: preferences.hamburger || {},
        desktopAccountMenuList,
        accounts: state.accounts || {
            userProfile: {
                firstName: null,
                accountId: null,
                rewardsStatus: null,
                userState: null,
                totalCerts: null,
            },
        },
        featureFlags,
        showWelcomeToolTip,
        headerLoaded: selectLazy(state)[headerLoad],
        showOverlay: state.showOverlay,
        shouldRender: selectShouldRender(state),
        activeCartConfig,
        toggleWiggleCart: state.toggleWiggleCart,
        enableKeepMeLoggedIn: selectKeepMeLoggedInThrottleFF(state),
        productSearchFocus: state.productSearchFocus,
        hambergurMenu: fetchVizNavIrisDepartmentData(state.irishambergurMenu),
        locationServiceStoreInfo: state.locationServiceReducer,
        showBgWhite: state.showBgWhite,
        galleryRerender: _get(state, 'rerender'),
        isFragment,
        isNative,
        pdpProductOffering: state.productOffering,
        findStoresDetails: state.findStoresDetails,
        HamburgerSlider: state.HamburgerSlider,
        rewardsHoverInfoMessagesTexts,
        requestUrl,
    };
};

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        Object.assign(
            analyticsActions,
            orderAction,
            itemCountAction,
            storeActions,
            storeNavigationActions,
            HamburgerAction,
            LocationAction,
            vendorAction,
            KeepMeLoggedInAction,
            FindStoresAction,
            {
                openSignInSlider,
                openRewardsPromotionLogin,
                getUserDetails,
                accessTimerStart,
                accessTimerAuthCheck,
                showLazy,
                showLogoutTooltip,
                openAccountMenuPage,
                loadUserStoreLocationInfo,
                updateUserStoreLocationInfo,
                productSearchFocusAction,
                customStepUp,
                updateStoreOnCartAction,
            },
            GalleryRerenderAction
        ),
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(YodaHeader);

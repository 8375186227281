import isEmpty from 'lodash/isEmpty';

/* istanbul ignore next */
const getLocationPath = (url = '') => {
    const locationPath = url.includes('undefined') ? '' : url;
    const seoUrl = locationPath === '/' ? '/home' : locationPath;
    return seoUrl;
};

/**
 * This method returns media type from the IRIS data for a device type
 *
 * @param {Object} deviceType object holding all the device types
 * @param {Object} mediaType object holding all the media types
 * @return {Object}
 *
 * Usage:
 * getMediaTypeForDeviceType({isMobile: true}, { smallMedia: {}})
 */

export const getMediaTypeForDeviceType = (deviceType, mediaType) => {
    const { isDesktop, isMobile, isTablet } = deviceType;
    const { media, smallMedia, mediumMedia } = mediaType;
    let selectedMedia = {};

    if (isDesktop) {
        selectedMedia = media;
    } else if (isMobile) {
        selectedMedia = smallMedia || media;
    } else if (isTablet) {
        selectedMedia = mediumMedia || media;
    }
    return selectedMedia || {};
};

export const findCardType = (cardNumber, context) => {
    if (!cardNumber) {
        return {};
    }
    const {
        preferences: { creditCardConfig = {} } = {},
        featureFlags: { enablevalidateCCRetry = true } = {},
    } = context;
    for (let i = 0; i < creditCardConfig.length; i += 1) {
        if (new RegExp(creditCardConfig[i].regex).test(cardNumber)) {
            if (enablevalidateCCRetry && cardNumber.length !== creditCardConfig[i].maxLength) {
                continue;
            }
            return creditCardConfig[i];
        }
    }
    return {};
};

/**
 * Converts favorite list to [...{id, itemId}] format
 * @param {Array} favList favoriteList
 */
export const convertFavoriteListForApp = (favList = []) => Object.values(favList);

/**
 * Get site key based on channel
 */
export const getEnterpriseSiteKey = (siteKeys) => {
    if (isEmpty(siteKeys)) return '';
    const userAgent = navigator.userAgent || null;
    /* istanbul ignore else */
    if (userAgent.indexOf('JCP_NATIVE_APPLICATION;WEB_VIEW')) {
        if (userAgent.indexOf('IOS_IPHONE') > 0) {
            return siteKeys.iOSWebView;
        }
        /* istanbul ignore else */
        if (userAgent.indexOf('ANDROID') > 0) {
            return siteKeys.androidWebView;
        }
    }
    return siteKeys.web;
};
export function getMonthName(month) {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return months[month];
}
export default getLocationPath;

import _isEmpty from 'lodash/isEmpty';
import Cookies from 'yoda-core-components/lib/helpers/Cookies/Cookies';
import LocalStorage from 'yoda-core-components/lib/helpers/LocalStorage/LocalStorage';
import TokenProvider from 'yoda-core-components/lib/helpers/TokenProvider/TokenProvider';
import ScriptLoader from 'yoda-core-components/lib/helpers/ScriptLoader/ScriptLoader';
import {
    LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE
} from '../../common/Constants';

export const getCookieOrLocalStorageData = (reqHeaders = {}, key) => {
    if (__SERVER__) {
        const reqCookies = reqHeaders?.cookies;
        return !_isEmpty(reqCookies) && reqCookies[key];
    }
    const localStorageValue = LocalStorage.getData(key);
    return _isEmpty(localStorageValue) ? Cookies.load(key) : localStorageValue;
};

export const getLocalizationZipcode = () => {
    const akamaiZipcode = Cookies.load("ak_zip")
    const userPreferredStore = Cookies.load(LOCATION_SERVICE_COOKIES_USER_PREFERRED_STORE);
    return akamaiZipcode || (userPreferredStore && JSON.parse(userPreferredStore)?.zipcode);
}

export const toCapitalize = (s) => {
    if (!s) {
        return '';
    }
    const oldStr = s.split(' ');
    const newStr = oldStr.map((i) => {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(i)) {
            const str = i.substring(1, 0).toUpperCase() + i.substring(1, i.length).toLowerCase();
            return str;
        }
        return i;
    });
    return newStr.join(' ');
};

export function getDeviceInfo(isNative = false, enableADIBrowserDeviceTransactionId = true) {
    const deviceInfo = {};
    let failedToFetchNativeDeviceTransactionId = false;
    if (!__SERVER__) {
        if (isNative) {
            if (Cookies.load('DP_APP_DEVICE_TRANSACTION_ID')) {
                deviceInfo.deviceTransactionId = Cookies.load('DP_APP_DEVICE_TRANSACTION_ID');
            } else if (enableADIBrowserDeviceTransactionId && window?._bcn?.dvc) {
                deviceInfo.deviceTransactionId = window._bcn.dvc.getTID();
                failedToFetchNativeDeviceTransactionId = true;
            }
        } else {
            if (window?._bcn?.dvc) {
                deviceInfo.deviceTransactionId = window._bcn.dvc.getTID();
            }
            if (window?._bcn) {
                deviceInfo.ubaToken = window._bcn.getToken();
                deviceInfo.ubaSessionId = window._bcn.getUbaSessionId();
                deviceInfo.ubaPageId = window._bcn.getPageId();
                deviceInfo.ubaEvents = window._bcn.getEvents();
            }
        }
    }
    return { deviceInfo, failedToFetchNativeDeviceTransactionId };
}

export function getAccertifyStatus(status) {
    return {
        isApproved: status === 'A',
        isDenied: status === 'R',
        isChallenge: status === 'C',
        isUnknown: status === 'U',
    };
}

/** Webvitals helper method */
export function getNumbersWithInterval(interval, end) {
    const numbers = [];
    for (let i = 0; i <= end; i += interval) {
        numbers.push(Math.round(i * 100) / 100);
    }
    return numbers;
}

function getWebvitalRange(num, intervals) {
    const intervalsLength = intervals?.length;
    if (num > intervals[intervalsLength - 1]) return `above${intervals[intervalsLength - 1]}`;
    for (let i = 0; i < intervalsLength; i++) {
        if (num >= intervals[i] && num <= intervals[i + 1]) {
            return intervals[i + 1];
        }
    }
}

export function constructWebVitalMetric(data, intervals) {
    const { name = '', value = '', rating = '' } = data;
    if (name === 'INP') return { name, value, rating };

    const metricToConverted = ['FCP', 'LCP', 'TTFB'];
    const convertedValue = metricToConverted.includes(name) ? value / 1000 : value;
    const result = { name, value: Math.round(convertedValue * 100) / 100, rating };
    const range = value && name ? getWebvitalRange(result.value, intervals[name]) : 0;
    result.interval = range;
    return result;
}
export function getModuleTrackingPageType(pathName) {
    if (pathName === '/cart') {
        return 'checkout';
    }
    if (pathName.includes('/checkout')) {
        return 'checkout';
    }
    if (pathName.includes('/p/')) {
        return 'pdp';
    }
    if (pathName.includes('/account/dashboard')) {
        return 'account';
    }
    return pathName;
}

export function getmoduleTrackingPageName(pathName) {
    if (pathName === '/cart') {
        return 'cart';
    }
    if (pathName.includes('/checkout/oc')) {
        return 'order confirmation';
    }
    if (pathName.includes('/p/')) {
        return 'pdp';
    }
    if (pathName.includes('account/dashboard')) {
        return 'cam dashboard';
    }
    return pathName;
}

export function loadBuyNowPayLaterWidget({
    src = '',
    partnerId = '',
    purchaseAmount = 0,
    successCb,
    errorCb,
}) {
    let modSrc = src.replace('CLIENT_PARTNERID', partnerId);
    modSrc = modSrc.replace('AMOUNT', purchaseAmount);
    try {
        const startTime = performance.now();
        const onSuccess = () => {
            const callbackTime = performance.now();
            // eslint-disable-next-line no-console
            console.log(`Successfully loaded bnpl script: ${callbackTime - startTime}`);
            successCb?.();
        };
        const onError = () => {
            // eslint-disable-next-line no-console
            console.log('error in loading SYF Buy Now Pay Later');
            errorCb?.();
        };
        ScriptLoader.loadBuyNowPayLater({
            src: modSrc,
            onSuccess,
            onError,
        });
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('Exception in bnpl load', e);
    }
}

export const isJcpCardLinkedToRewards = () => {
    return (
        TokenProvider.get('DP_REWARDS_TIER') === 'credit' ||
        TokenProvider.get('DP_REWARDS_TIER') === 'CREDIT'
    );
};

export default {
    toCapitalize,
    getCookieOrLocalStorageData,
    getDeviceInfo,
    getAccertifyStatus,
    getNumbersWithInterval,
    constructWebVitalMetric,
    loadBuyNowPayLaterWidget,
};

/* istanbul ignore file */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames/bind';
import get from 'lodash/get';
import { dt } from 'yoda-core-components/lib/helpers/Utils/GetTailwindToken';
import Icon from 'yoda-core-components/lib/components/Icon/Icon';
import Play from 'yoda-core-components/lib/assets/svg/Play.svg';
import styles from './SlickCarousel.css';
import HorizontalCarousel from '../Carousel/HorizontalCarousel';

const cx = classNames.bind(styles);

export default class SlickCarousel extends Component {
    /**
     * Supported React properties
     * @type {Object}
     */

    static propTypes = {
        /**
         * Required field to pass data array to create carousel slides.
         */
        carouselData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

        /**
         * Renderer method that returns React component or any other HTML
         */
        carouselItemRenderer: PropTypes.func.isRequired,

        /**
         * Minimum displace ment required in pixels to trigger the touch event actions.
         */
        // minimumSliderTouchThreshold: PropTypes.number,

        /**
         * Unique name for referencing dom element in automation testing
         * @type {String}
         */
        automationId: PropTypes.string,

        /**
         * Callback function when slide occurs
         * @type {Function}
         */
        slideCallback: PropTypes.func,

        /**

         /**
         * themeConfig will help in applying a different theme
         * i.e, User can override the default theme by passing
         * themeConfig as props
         * @type {boolean}
         */
        themeConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
        autoplay: PropTypes.bool,
        speed: PropTypes.number,
        fade: PropTypes.bool,
        pauseOnHover: PropTypes.bool,
        pauseOnDotsHover: PropTypes.bool,
        displayPagination: PropTypes.bool,
        displayArrows: PropTypes.bool,
        rewindOnChange: PropTypes.bool,
        repeat: PropTypes.bool,
        autoSize: PropTypes.bool,
        center: PropTypes.bool,
        lazyLoad: PropTypes.bool,
        updateRender: PropTypes.bool,
        isBundle: PropTypes.bool,
        slideInitialized: PropTypes.bool,
        carouselBulletTheme: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
        autoPlay: PropTypes.bool,
        isCarouselTabDisplay: PropTypes.bool,
        isPromoBanner: PropTypes.bool,
        isPDPImage: PropTypes.bool,
        isbotWrapperClass: PropTypes.string,
        carouselSlideDefault: PropTypes.bool,
        setActiveDefaultCarousel: PropTypes.func,
        enableMobileSwatchTap: PropTypes.bool,
        enableVideoPlacementChange: PropTypes.bool,
        sponsoredProduct: PropTypes.bool,
        OnClickBeacon: PropTypes.string,
        ppId: PropTypes.string,
    };

    static defaultProps = {
        carouselData: {},
        automationId: '',
        themeConfig: [],
        repeat: true,
        rewindOnChange: false,
        autoplay: false,
        speed: 300,
        fade: false,
        pauseOnHover: false,
        pauseOnDotsHover: false,
        displayArrows: true,
        slideCallback: null,
        carouselItemRenderer: () => {},
        displayPagination: true,
        autoSize: false,
        center: false,
        lazyLoad: false,
        updateRender: false,
        isBundle: false,
        slideInitialized: false,
        carouselBulletTheme: {},
        autoPlay: false,
        isCarouselTabDisplay: false,
        isPromoBanner: false,
        isPDPImage: false,
        isbotWrapperClass: '',
        carouselSlideDefault: false,
        enableMobileSwatchTap: false,
        setActiveDefaultCarousel: () => {},
        enableVideoPlacementChange: false,
        sponsoredProduct: false,
        OnClickBeacon: '',
        ppId: '',
    };

    /**
     * Gets the theme to use
     * @return {[Object]} [theming classes]
     */
    static getThemeConfig(themeConfig) {
        return {
            carouselSelected: styles.selected,
            carouselBullets: dt(['fill-gray-15']),
            bullet: styles.bullet,
            nextWrapper: styles.nextWrapper,
            previousWrapper: styles.previousWrapper,
            next: styles.next,
            previous: styles.previous,
            slideClass: styles.slide,
            activeSlideClass: dt(['fill-gray-70']),
            ...themeConfig,
        };
    }

    /* istanbul ignore next */
    constructor(props) {
        super(props);
        this.themeConfig = SlickCarousel.getThemeConfig(props.themeConfig);
        this.state = {
            videoLblSelected: {
                carousalIndex: null,
                videoID: null,
            },
        };
    }
    // fix found at https://github.com/akiran/react-slick/issues/1240#issuecomment-396484553

    componentDidMount() {
        if (!__SERVER__ && this.props.slideInitialized) {
            window.addEventListener('resize', this.slidetoNext);
            window.addEventListener('scroll', this.slidetoNext);
        }
        try {
            if (this.reactSlickWrapper && this.reactSlickWrapper.current) {
                this.reactSlickWrapper.current.addEventListener('touchstart', this.touchStart);
                this.reactSlickWrapper.current.addEventListener('touchmove', this.preventTouch, {
                    passive: false,
                });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.log(err);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { rewindOnChange, carouselData } = this.props;
        /* istanbul ignore else */
        if (rewindOnChange && nextProps.carouselData !== carouselData) {
            this.slide(0);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {
            videoLblSelected: { videoID, carousalIndex },
        } = this.state;
        const {
            videoLblSelected: { videoID: nextVideoID, carousalIndex: nextCarousalIndex },
        } = nextState;

        if (this.props.carouselData !== nextProps.carouselData) {
            return true;
        }

        /* istanbul ignore if */
        if (this.props.themeConfig !== nextProps.themeConfig) {
            this.themeConfig = SlickCarousel.getThemeConfig(nextProps.themeConfig);
            return true;
        }

        /* istanbul ignore if */
        if (this.props.center !== nextProps.center) {
            return true;
        }

        if (this.props.updateRender !== nextProps.updateRender) {
            return true;
        }

        /* istanbul ignore if */
        if (videoID !== nextVideoID || carousalIndex !== nextCarousalIndex) {
            return true;
        }

        return false;
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('scroll', this.slidetoNext);
            window.removeEventListener('resize', this.slidetoNext);
            /* istanbul ignore else */
            if (this.reactSlickWrapper && this.reactSlickWrapper.current) {
                this.reactSlickWrapper.current.removeEventListener('touchstart', this.touchStart);
                this.reactSlickWrapper.current.removeEventListener('touchmove', this.preventTouch, {
                    passive: false,
                });
            }
        } catch (err) {
            /* istanbul ignore next */
            console.log(err);
        }
    }

    touchStart(e) {
        this.firstClientX = e.touches[0].clientX;
        this.firstClientY = e.touches[0].clientY;
    }

    preventTouch(e) {
        const minValue = 5; // threshold

        this.clientX = e.touches[0].clientX - this.firstClientX;
        this.clientY = e.touches[0].clientY - this.firstClientY;

        // Vertical scrolling does not work when you start swiping horizontally.
        if (Math.abs(this.clientX) > minValue) {
            e.preventDefault();
            e.returnValue = false;
            return false;
        }
        return true;
    }

    /* istanbul ignore next */
    slidetoNext = () => {
        if (this.props.autoplay) {
            this.slideTime && clearTimeout(this.slideTime);
            this.slideTime = setTimeout(() => {
                if (window.pageYOffset < 100) {
                    this.slide(this.slideIndex + 1);
                }
            }, 3000);
        }
    };

    slideCallback = (idx) => {
        const { isBundle } = this.props;
        /* istanbul ignore next */
        idx = isNaN(idx) ? 1 : idx;
        this.slideIndex = idx;
        !isBundle && this.props.slideCallback && this.props.slideCallback(idx);
        const videoLblSelectedTmp = { ...this.state.videoLblSelected };
        const scrollElement = document.getElementById('videoLabels');
        /* istanbul ignore next */
        if (this.props.carouselData && this.props.carouselData?.[idx]?.id) {
            videoLblSelectedTmp.carousalIndex = idx;
            videoLblSelectedTmp.videoID = this.props.carouselData?.[idx]?.id;
            this.setState({ videoLblSelected: videoLblSelectedTmp });
        } else {
            videoLblSelectedTmp.carousalIndex = null;
            videoLblSelectedTmp.videoID = null;
            this.setState({ videoLblSelected: videoLblSelectedTmp });
        }
        /* istanbul ignore next */
        if (scrollElement && this.props.carouselData && this.props.carouselData?.[idx]?.isVideo) {
            const totalVideos = this.props.carouselData.filter((item) => item.isVideo);
            const selectedElement = document.getElementById(
                get(this.state.videoLblSelected, 'videoID', null)
            );
            idx > totalVideos.length / 2
                ? scrollElement.scrollTo(selectedElement?.getBoundingClientRect().left - 10, 0)
                : scrollElement.scrollTo(selectedElement?.getBoundingClientRect().left + 10, 0);
        }
    };

    /* istanbul ignore next */
    slide = (idx) => {
        idx = isNaN(idx) ? 1 : idx;
        this.reactSlick && this.reactSlick.slickGoTo(idx);
    };

    itemRenderer = (...args) =>
        this.themeConfig.slideClass ? (
            <div className={cx(this.themeConfig.slideClass)}>
                {this.props.carouselItemRenderer.apply(null, args)}
            </div>
        ) : (
            <>{this.props.carouselItemRenderer.apply(null, args)}</>
        );

    customPagination = (idx) => {
        idx = isNaN(idx) ? 1 : idx;
        const carouselDataItem = this.props.carouselData[idx];
        const paginationClasses = cx(
            { [this.themeConfig.bullet]: !carouselDataItem.icon },
            carouselDataItem.icon ? 'iconButton' : ''
        );

        let iconComponent;
        let iconComponentStyle;
        if (carouselDataItem.icon && !iconComponent) {
            iconComponent =
                carouselDataItem.iconComponent || (carouselDataItem.icon === 'play' && <Play />);
            iconComponentStyle = carouselDataItem.icon === 'play' ? ['fill-gray-15'] : [''];
        }

        return (
            <button
                key={carouselDataItem.key}
                className={paginationClasses}
                data-automation-id={`carousel-pgn-${idx}`}
            >
                {
                    /* istanbul ignore next */
                    iconComponent ? (
                        <Icon
                            iconType="svg"
                            className={[cx('svg-icon'), dt([...iconComponentStyle])].join(' ')}
                            width="20px"
                            height="20px"
                            viewBox="0 0 25 25"
                        >
                            {iconComponent}
                        </Icon>
                    ) : null
                }
            </button>
        );
    };

    /* istanbul ignore next */
    videoLabelClickHandler = (e) => {
        e && e.preventDefault && e.preventDefault();
        const targetID = get(e.currentTarget, 'id', null);
        let idx = 0;
        this.props.carouselData.forEach((item, index) => {
            if (item.id === targetID) {
                idx = index;
            }
        });
        this.slide(idx);
    };

    render() {
        const {
            carouselData,
            isBundle,
            isCarouselTabDisplay,
            isPromoBanner,
            isbotWrapperClass,
            enableVideoPlacementChange,
        } = this.props;
        const {
            videoLblSelected: { videoID },
        } = this.state;

        const productVideoLabels = carouselData.map((item) =>
            item.isVideo ? (
                <div className={cx('videoLabelContainer')}>
                    <a
                        id={item.id}
                        href={item.url}
                        className={dt(['flex', 'items-center'])}
                        onClick={this.videoLabelClickHandler}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div
                            className={`${dt([
                                'w-6',
                                'h-6',
                                'rounded-half',
                                'mr-1',
                                'border',
                                'border-solid',
                                'border-gray-60',
                                'relative',
                            ])} ${cx('videoIcon')}`}
                        >
                            <span
                                className={`${dt([
                                    videoID === item.id && 'border-transparent',
                                    videoID === item.id && 'border-l-black',
                                ])} ${cx('playIcon')}`}
                            />
                        </div>
                        <div
                            className={`${dt([
                                videoID === item.id && 'font-open-sans',
                                videoID === item.id && 'font-bold',
                                videoID === item.id && 'text-black',
                            ])} ${cx('videoLabel', 'labelTxt')}`}
                        >
                            {
                                /* istanbul ignore next */
                                item.label ? item.label : 'Product Video'
                            }
                        </div>
                    </a>
                </div>
            ) : null
        );

        const slickDefaultProps = {
            useCss: true,
            speed: 300,
            centerPadding: '0px',
            touchThreshold: 100,
            dotsClass: `${cx('bulletContainer')} ${this.themeConfig.bulletContainer}`,
            swipe: true,
            swipeToSlide: true,
        };
        const slickProps = {
            ...slickDefaultProps,
            afterChange: this.slideCallback,
            arrows: this.props.displayArrows,
            autoplay: this.props.autoplay,
            fade: this.props.fade,
            pauseOnHover: this.props.pauseOnHover,
            pauseOnDotsHover: this.props.pauseOnDotsHover,
            infinite: this.props.repeat,
            dots: this.props.displayPagination,
            customPaging: this.props.displayPagination && this.customPagination,
            variableWidth: !this.props.autoSize,
            centerMode: this.props.center,
            lazyLoad: this.props.lazyLoad,
            speed: this.props.speed,
        };

        return (
            <>
                <section
                    className={cx(styles.carousel, { bundleImageContainer: isBundle })}
                    data-automation-id={this.props.automationId}
                    ref={(reactSlickWrapper) => {
                        this.reactSlickWrapper = reactSlickWrapper;
                    }}
                >
                    <div className={this.themeConfig.carouselBlock}>
                        <div
                            data-automation-id="main"
                            className={`${dt(['overflow-hidden'])} ${
                                (this.themeConfig.carouselContainer,
                                this.themeConfig.slickTrackClass)
                            }`}
                            ref={(reactSlickWrapper) => {
                                this.reactSlickWrapper = reactSlickWrapper;
                            }}
                        >
                            {this.props.carouselData.length > 1 ? (
                                <>
                                    <HorizontalCarousel
                                        isCarouselTabDisplay={isCarouselTabDisplay}
                                        className={this.themeConfig.carouselContainer}
                                        key={this.props.carouselData.length}
                                        ref={(reactSlick) => {
                                            this.reactSlick = reactSlick;
                                        }}
                                        direction="horizontal"
                                        carouselBulletTheme={this?.props?.carouselBulletTheme}
                                        autoPlay={this?.props?.autoPlay}
                                        arrows={this?.props?.displayArrows}
                                        carouselData={this.props.carouselData}
                                        isPromoBanner={this?.props?.isPromoBanner}
                                        infinite={this?.props?.repeat}
                                        slideCallback={this.slideCallback}
                                        isPDPImage={this.props?.isPDPImage}
                                        carouselSlideDefault={this?.props?.carouselSlideDefault}
                                        setActiveDefaultCarousel={
                                            this?.props?.setActiveDefaultCarousel
                                        }
                                        enableMobileSwatchTap={this?.props?.enableMobileSwatchTap}
                                        enableVideoPlacementChange={enableVideoPlacementChange}
                                        sponsoredProduct={this?.props?.sponsoredProduct}
                                        OnClickBeacon={this?.props?.OnClickBeacon}
                                        ppId={this?.props?.ppId}
                                        skuId={this?.props?.skuId}
                                    >
                                        {this.props.carouselData.map(this.itemRenderer)}
                                    </HorizontalCarousel>
                                </>
                            ) : (
                                <div
                                    className={
                                        isCarouselTabDisplay
                                            ? dt([
                                                  'flex',
                                                  'border-b-2',
                                                  'border-light-white',
                                                  'border-solid',
                                              ])
                                            : cx('singleImageCarousel')
                                    }
                                >
                                    {this.props.carouselData.map(this.itemRenderer)}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                {!enableVideoPlacementChange && (
                    <div
                        className={`${dt([
                            'whitespace-nowrap',
                            'overflow-x-auto',
                            'flex',
                            'flex-wrap',
                            'smOnly:px-6',
                        ])} ${cx('videoLabelsContainer', isbotWrapperClass)}`}
                        id="videoLabels"
                    >
                        {
                            /* istanbul ignore next */
                            isBundle ? null : productVideoLabels
                        }
                    </div>
                )}
            </>
        );
    }
}

// Sign in action types
export const SIGNIN_SLIDER_POST_SIGNIN_SUCCESS = 'SIGNIN_SLIDER_POST_SIGNIN_SUCCESS';
export const SIGNIN_SLIDER_POST_SIGNIN_ERROR = 'SIGNIN_SLIDER_POST_SIGNIN_ERROR';

// Create account action types
export const SIGNIN_SLIDER_CREATE_ACCOUNT_ERROR = 'SIGNIN_SLIDER_CREATE_ACCOUNT_ERROR';
export const SIGNIN_SLIDER_CREATE_ACCOUNT_SUCCESS = 'SIGNIN_SLIDER_CREATE_ACCOUNT_SUCCESS';
export const NATIVE_SIGNIN_SLIDER_CREATE_ACCOUNT = 'NATIVE_SIGNIN_SLIDER_CREATE_ACCOUNT';
export const NATIVE_SIGNIN_SLIDER_CREATE_ACCOUNT_SUCCESS =
    'NATIVE_SIGNIN_SLIDER_CREATE_ACCOUNT_SUCCESS';

/**
 * Show Password reset action types
 */
// Using Password Reset Action Types, Actions and Reducers for all password reset flow
// export const SIGNIN_SLIDER_PASSWORD_RESET_SUCCESS = 'SIGNIN_SLIDER_PASSWORD_RESET_SUCCESS';
// export const SIGNIN_SLIDER_PASSWORD_RESET_ERROR = 'SIGNIN_SLIDER_PASSWORD_RESET_ERROR';
export const SIGNIN_SLIDER_FORGOT_PASSWORD_ANALYTICS = 'SIGNIN_SLIDER_FORGOT_PASSWORD_ANALYTICS';

// sign in slider specific action types
export const SIGNIN_SLIDER_CREATE_ACCOUNT = 'SIGNIN_SLIDER_CREATE_ACCOUNT';
export const SIGNIN_SLIDER_PASSWORD_RESET = 'SIGNIN_SLIDER_PASSWORD_RESET';
export const SIGNIN_SLIDER_RESET = 'SIGNIN_SLIDER_RESET';
export const SIGNIN_SLIDER_ACCOUNT_MENU = 'SIGNIN_SLIDER_ACCOUNT_MENU';
export const SIGNIN_SLIDER_HIDE_WELCOME_TOOL_TIP = 'SIGNIN_SLIDER_HIDE_WELCOME_TOOL_TIP';
export const SIGNIN_SLIDER_SHOW_LOGOUT_TOOL_TIP = 'SIGNIN_SLIDER_SHOW_LOGOUT_TOOL_TIP';
export const SIGNIN_SLIDER_LOGOUT_API_REQUEST = 'SIGNIN_SLIDER_LOGOUT_API_REQUEST';

export const SIGNIN_SLIDER_OPEN = 'SIGNIN_SLIDER_OPEN';
export const SIGNIN_SLIDER_MOUNTED = 'SIGNIN_SLIDER_MOUNTED';
export const INITIATE_SIGNIN_SLIDER_CLOSE = 'INITIATE_SIGNIN_SLIDER_CLOSE';
export const UNMOUNT_SIGNIN_SLIDER = 'UNMOUNT_SIGNIN_SLIDER';

export const CUSTOM_STEPUP_SIGNIN_SLIDER = 'CUSTOM_STEPUP_SIGNIN_SLIDER';
export const CUSTOM_STEPUP_SIGNIN_SLIDER_SKIP = 'CUSTOM_STEPUP_SIGNIN_SLIDER_SKIP';
export const CUSTOM_STEPUP_ALREADY_AUTHENTICATED = 'CUSTOM_STEPUP_ALREADY_AUTHENTICATED';
export const LNL_PAGE_EMAIL_FOCUS = 'LNL_PAGE_EMAIL_FOCUS';
export const DISPLAY_ENROLL_PHONE = 'DISPLAY_ENROLL_PHONE';

export const NEW_ENROLL_REWARD_LINK = 'NEW_ENROLL_REWARD_LINK';
export const NEW_ENROLL_REWARD_LINK_ERROR = 'NEW_ENROLL_REWARD_LINK_ERROR';
export const NEW_ENROLL_REWARD_LINK_SUCCESS = 'NEW_ENROLL_REWARD_LINK_SUCCESS';
export const CLEAR_NEW_ENROLL_REWARD_LINK_ERROR = 'CLEAR_NEW_ENROLL_REWARD_LINK_ERROR';

export const OPEN_REWARDS_PROMOTIONAL_LOGIN = 'OPEN_REWARDS_PROMOTIONAL_LOGIN';
